export const spanishTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Las líneas de pedidos se han actualizado correctamente.',
  'Le texte a été enregistré avec succès':
    'El texto se ha registrado correctamente',
  'La traduction a été enregistrée avec succès':
    'La traducción ha sido guardada con éxito',
  'Texte + Traduction enregistré avec succès':
    'Texto + Traducción ha sido guardada con éxito',
  'Nombre de mots': 'Número de palabras',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Nombre y apellidos del cliente',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'La fecha debe ser posterior a hoy.',
  'Contenu rédigé': 'Contenido escrito',
  'Nom/prénom': 'Apellido/nombre',
  'Mot de passe incorrect': 'Contraseña incorrecta',
  Institutions: 'Instituciones',
  'Veuillez donner un descriptif au document téléchargé':
    'Por favor, describa el documento descargado',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Siguiente',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"':
    'Separe las palabras clave con un "enter".',
  Allemand: 'Alemán',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'se ha enviado a su correo electrónico un mensaje con un enlace para restablecer la contraseña',
  'Chef edito': 'Redactor jefe',
  Croate: 'Croata',
  'Nb de textes': 'Nº de textos',
  Violet: 'Violeta',
  'Ce champs doit être sup ou égale nombre de texte':
    'Este campo debe ser mayor o igual que el número de texto',
  'a été activé avec succès': 'se ha activado correctamente',
  Népalais: 'Nepalés',
  'Nombre de FAQ': 'Número de preguntas frecuentes',
  Urdu: 'Urdu',
  'Analyses restantes': 'Análisis pendientes',
  Indonésien: 'Indonesia',
  Breton: 'Bretón',
  Livrés: 'Entregado',
  Associations: 'Asociaciones',
  'Le client': 'El cliente',
  'Révision demandée': 'Revisión solicitada',
  "Une erreur s'est produite, veuillez réessayer":
    'Se ha producido un error, inténtelo de nuevo',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Actualización no autorizada al entregar los textos',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Para añadir texto (optimización del contenido existente)',
  Gaélique: 'Gaélico',
  'La ligne de commande pour': 'La línea de comandos para',
  'Business/Management': 'Empresa/Gestión',
  Serbe: 'Serbio',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Indique a continuación sus solicitudes de modificación, teniendo en cuenta nuestras condiciones de planchado',
  'rédacteurs trouvés': 'redactores encontrados',
  'Kirghiz ; Kirghiz': 'Kirguís; Kirguís',
  Différence: 'Diferencia',
  'en cours': 'en curso',
  'Sami du Nord': 'Sami septentrional',
  Comparer: 'Compara',
  Lituanien: 'Lituania',
  'Typix - Mon tableau de bord': 'Typix - Mi panel de control',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Debe tener al menos 8 caracteres con una letra mayúscula, un número, un carácter especial y no ser idéntico al nombre',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Introduzca campos válidos',
  Basque: 'Vasco',
  'Exporter .csv': 'Exportar .csv',
  'Confirmer le nouveau mot de passe': 'Confirmar nueva contraseña',
  "Pas d'options": 'Sin opciones',
  'Banque/Assurance': 'Banca/Seguros',
  'Vos demandes': 'Sus peticiones',
  'Coût d\u2019achat': 'Coste de compra',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès':
    'El texto se ha eliminado correctamente',
  'Mes Commandes': 'Mis pedidos',
  Zoulou: 'Zulú',
  Afficher: 'Ver',
  Texte: 'Texto',
  Islandais: 'Islandia',
  Russe: 'Ruso',
  'Tous les textes': 'Todos los textos',
  Czech: 'Checa',
  'Comparaison hors ligne': 'Comparación offline',
  Téléphonie: 'Telefonía',
  'Valider le texte': 'Validar el texto',
  'Votre text ne de doit pas dépasser 500 mots':
    'Su texto no debe superar las 500 palabras',
  'Coquilles (double espace, « s »\u2026)': 'Conchas (doble espacio, "s"...)',
  'le consultant': 'el consultor',
  Coréen: 'Coreano',
  'Nb de Silo': 'Nº de silos',
  Validés: 'Validado',
  'non attribuées': 'sin asignar',
  Néerlandais: 'Holandés',
  'Voulez-vous vraiment supprimer': '¿De verdad quieres borrar',
  Rundi: 'Rundi',
  Regional: 'Regional',
  'Compte client': 'Cuenta cliente',
  Global: 'Global',
  'Tous les rôles': 'Todas las funciones',
  Musique: 'Música',
  Perso: 'Personal',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Parece que no tiene más crédito de análisis',
  Bambara: 'Bambara',
  Sortir: 'Fuera',
  optionel: 'opcional',
  'Temps restant': 'Tiempo restante',
  Ukrainien: 'Ucraniano',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Su solicitud de revisión ha sido enviada.',
  'Typix - Créer rédacteur': 'Typix - Crear un redactor',
  "n'existe pas": 'no existe',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'no puede suprimirse, ya que está vinculado a sitios web',
  'Commandes en cours': 'Pedidos en curso',
  'Textes livrés': 'Textos entregados',
  Validation: 'Validación',
  'Non attribuées': 'No asignado',
  'Voulez-vous vraiment désactiver': '¿Realmente desea desactivar',
  'Catalan ; Valencien': 'Catalán; Valenciano',
  'Nombre de textes restants': 'Número de textos restantes',
  'Ne doit pas contenir votre nom': 'No debe contener su nombre',
  Documentation: 'Documentación',
  Kazakh: 'Kazajstán',
  'Numéro invalide': 'Número no válido',
  'Veuillez saisir un texte ou importer un fichier.':
    'Introduzca un texto o importe un archivo.',
  'Nb de FAQ': 'Nº de FAQ',
  Analyser: 'Analice',
  Turc: 'Turco',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Cualquier solicitud no especificada en el escrito',
  Wallon: 'Valonia',
  Birmane: 'Birmano',
  Irlandais: 'Irlandés',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Restablecer contraseña',
  Cuisine: 'Cocina',
  'Haïtien ; créole haïtien': 'Haitiano; Criollo haitiano',
  'Vous ne pouvez pas supprimer le rédacteur': 'No puede eliminar el redactor',
  Validé: 'Validado',
  "cet email n'existe pas": 'este correo electrónico no existe',
  Historique: 'Historia',
  Confirmer: 'Confirme',
  'à cocher si le nombre de mots est différent par texte':
    'marcar si el número de palabras es diferente por texto',
  'Fiche du site web ': 'Ficha del sitio web',
  Désactiver: 'Desactivar',
  'Travaux/BTP': 'Obras/Construcción',
  'La fiche du website': 'La ficha del sitio web',
  'Si le contenu ne respecte pas les règles du brief':
    'Si el contenido no respeta las normas del escrito',
  Norvégien: 'Noruega',
  '0 rédacteurs': '0 redactores',
  indisponible: 'no disponible',
  Venda: 'Venda',
  Maori: 'Maorí',
  'Textes revisés': 'Textos revisados',
  'Status texte': 'Estado del texto',
  'textes livrés en attente de validation':
    'textos entregados en espera de validación',
  'Typix - Mon Profile': 'Typix - Mi perfil',
  'le client': 'el cliente',
  Romanche: 'Romanche',
  'URL du site': 'URL del sitio',
  'nouvelles notifications': 'nuevas notificaciones',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maldivo',
  'Êtes vous sur de vouloir sauvegarder le texte':
    '¿Estás seguro de que quieres guardar el texto',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Añada un texto inicial para guardar el texto como borrador',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'El contrato para el cliente',
  rédacteurs: 'redactores',
  'Ce champ est obligatoire': 'Este campo es obligatorio',
  'Tarif/Mot': 'Tasa/Palabra',
  Tadjik: 'Tayiko',
  'Réf devis': 'Presupuesto de referencia',
  'Rechercher un rédacteur': 'Buscar un redactor',
  Albanais: 'Albanés',
  Galicien: 'Gallego',
  Disponibilité: 'Disponibilidad',
  'Les deux mots de passe ne sonts pas identiques':
    'Las dos contraseñas no son idénticas',
  Tibétain: 'Tibetano',
  'Typix - Gestion des utilisateurs': 'Typix - Gestión de usuarios',
  'Ossétien ; Ossétique': 'Osetia; osetia',
  Tswana: 'Tswana',
  'Ajouter un client': 'Añadir un cliente',
  'Séparez les mots-clés par un " Enter ".':
    'Separe las palabras clave con una "Intro".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Temas',
  'Nb mots/texte': 'Número de palabras/texto',
  Commerce: 'Comercio',
  'Voir les commandes': 'Ver los pedidos',
  'Nombre de mots par texte': 'Número de palabras por texto',
  'Lien drive...': 'Unidad de enlace...',
  'Hollandais ; Flamand': 'Neerlandés; Flamenco',
  'Mon profil': 'Mi perfil',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Este campo debe ser mayor que 0 porque la facturación no es real',
  'Le texte a été validé avec succès': 'El texto se ha validado correctamente',
  Jeux: 'Juegos',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    '¿Realmente desea eliminar la línea de comandos para',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Norte; Ndebele Norte',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Eslavo eclesiástico; Eslavo antiguo; Eslavo eclesiástico; Búlgaro antiguo; Eslavo eclesiástico antiguo',
  Swahili: 'Swahili',
  Voyage: 'Viajar',
  'Nombre de mot à ajouter': 'Número de palabras a añadir',
  'Liste des rédacteurs': 'Lista de redactores',
  'le rédacteur': 'el redactor',
  Kanuri: 'Kanuri',
  rédacteur: 'redactor',
  Tatar: 'Tártaro',
  'Roumain ; Moldave ; Moldovan': 'Rumano; moldavo',
  'Le texte initial de': 'El texto original del',
  'Mail clients': 'Correo del cliente',
  'Le contrat de': 'El contrato de',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'al menos una letra minúscula y una mayúscula, un carácter especial y un número',
  Transport: 'Transporte',
  'Nombre total de mots (existants et ajoutés) :':
    'Número total de palabras (existentes y añadidas) :',
  'Rechercher par mail, prénom, nom, site...':
    'Búsqueda por correo electrónico, nombre, apellidos, sitio...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Número de palabras a añadir (Mín/Máx) :',
  Compte: 'Cuenta',
  'Tourisme/Loisirs': 'Turismo/Ocio',
  'Nouveau texte': 'Nuevo texto',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; groenlandés',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Hay uno o más errores en la ventana de brecha semántica. Compruebe su contenido para corregirlo y vuelva a enviar el texto.',
  'ne peut pas être supprimé pour le moment':
    'no se puede eliminar en este momento',
  'Le texte': 'El texto',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Ya existe un usuario con la dirección de correo electrónico',
  Cree: 'Cree',
  Prénom: 'Nombre',
  'commandes prête': 'pedidos listos',
  Réinitialiser: 'Restablecer',
  'Veuillez fournir une date valide': 'Indique una fecha válida',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luxemburgués; Letzeburgesch',
  Suédois: 'Sueco',
  'Confirmer le mot de passe': 'Confirmar contraseña',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Este campo es obligatorio y sólo puede contener números',
  Sciences: 'Ciencia',
  Tchétchène: 'Chechenia',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'es el único cliente de un sitio, hay que añadir otros para eliminarlo',
  'Le mot de passe de confirmation ne correspond pas':
    'La contraseña de confirmación no coincide',
  Swati: 'Swati',
  Danois: 'Danés',
  Sauvegarder: 'Guardar',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Modifique la siguiente información: título y/o palabra clave',
  'importer un document': 'importar un documento',
  Maltais: 'Maltés',
  'Nb de mots': 'Número de palabras',
  'Les données fournies sont invalides':
    'Los datos proporcionados no son válidos',
  'Livraison estimée': 'Entrega estimada',
  'Grec moderne (1453-)': 'Griego moderno (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Número total de palabras añadidas',
  'le contenu présente des inexactitudes':
    'el contenido contiene inexactitudes',
  Finnois: 'En finés',
  'Ajouter un admin': 'Añadir un administrador',
  'Min/Max': 'Mín/Máx',
  'Modifier le consultant': 'Modificar el consultor',
  'Liste des clients': 'Lista de clientes',
  'Nom client': 'Nombre del cliente',
  Vert: 'Verde',
  'Mots clés manquants': 'Palabras clave que faltan',
  Lao: 'Lao',
  Somali: 'Somalí',
  'Nb de silo par texte': 'Número de silos por texto',
  Italien: 'Italiano',
  'La commande a été mis à jour avec succès !':
    'El pedido se ha actualizado correctamente.',
  Bosniaque: 'Bosnia',
  'Thématiques d\u2019expertise': 'Temas de especialización',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Esta es la contraseña que has olvidado',
  'a été désactivé avec succès': 'se ha desactivado correctamente',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Solicitar la orden',
  'mot-clé': 'palabra clave',
  Abkhazien: 'Abjasia',
  Mongolien: 'Mongolia',
  'Typix - Fiche site web': 'Typix - Sitio web',
  'Mail envoyé': 'Correo enviado',
  Géorgien: 'Georgiano',
  'Si des phrases ne sont pas dans un français correct':
    'Si las frases no están en francés correcto',
  'En cours': 'En curso',
  voir: 'véase',
  "Date d'ajout": 'Fecha añadida',
  'Tarif au mot': 'Tarifa por palabra',
  Thai: 'Tailandés',
  'Détails du texte': 'Detalles del texto',
  'Commandes en retard': 'Pedidos tardíos',
  disponible: 'disponible',
  Droit: 'Ley',
  Azerbaïdjanais: 'Azerbaiyán',
  'Ajouter un consultant': 'Añadir un consultor',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Al reproducir contenidos, sólo se admitirán los siguientes elementos',
  'doit comprendre au moins 8 charactères':
    'debe contener al menos 8 caracteres',
  'Titre de la commande': 'Título de la orden',
  'Langues bihari': 'Lenguas bihari',
  'Livraison estimé au': 'Entrega estimada para',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'El número de textos de la orden no puede ser inferior al número de textos asignados al redactor o redactores. Por favor, cambie primero la información en la línea de comandos',
  'Date de livraison': 'Fecha de entrega',
  'Voulez-vous vraiment supprimer le contrat de':
    '¿De verdad quieres abolir la',
  'Facturation au réel': 'Facturación real',
  'Gap sémantique': 'Brecha semántica',
  Estonien: 'Estonia',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Coste máximo de compra/palabra',
  'Détail de la commande': 'Detalles del pedido',
  'Export en cours de téléchargement\u2026':
    'Se está cargando la exportación...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Este campo es obligatorio y debe ser inferior o igual al Número total de textos del pedido',
  Arabe: 'Árabe',
  Bislama: 'Bislama',
  'Mail client': 'Correo del cliente',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Título, palabras clave, url, etiqueta, meta-descripción)',
  'Typix - Nouvelle commande': 'Typix - Nuevo pedido',
  'Révision en attente': 'Revisión pendiente',
  'Le consultant': 'El asesor',
  Entreprise: 'Empresa',
  Aragonais: 'Aragonesa',
  Kashmiri: 'Cachemira',
  'a été activé': 'se ha activado',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Asociación Internacional de Lenguas Auxiliares)',
  Actualités: 'Noticias',
  'Votre décision a été prise en compte avec succès':
    'Su decisión ha sido tenida en cuenta',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'contenido pendiente de verificación por el cliente',
  'Fiche du rédacteur': 'Archivo del redactor',
  Outillage: 'Herramientas',
  'Typix - Détails de la commande': 'Typix - Detalles del pedido',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Añada o complete su contenido a continuación:',
  Ewe: 'Ewe',
  'contenu en attente de repasse rédacteur':
    'contenido pendiente redactor de repeticiones',
  'La commande a été supprimé avec succès':
    'El pedido se ha eliminado correctamente',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Expresiones o frases en francés que no le convienen',
  Corse: 'Córcega',
  Modifier: 'Editar',
  'Nb de GMB': 'Nº de GMB',
  Oromo: 'Oromo',
  Polish: 'Polaco',
  'Insérez votre gap sémantique avec l’occurrence':
    'Inserte su hueco semántico con la ocurrencia',
  'Nb de textes à optimiser': 'Número de textos que deben optimizarse',
  Mode: 'Modo',
  Akan: 'Akan',
  'Révisions effectués': 'Revisiones realizadas',
  'Modifier l\u2019admin': 'Editar el admin',
  'Télécharger le document': 'Descargar el documento',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Notas de los redactores',
  Quechua: 'Quechua',
  Féroïen: 'Feroés',
  'Modifier le rédacteur': 'Cambiar el redactor',
  Mail: 'Correo',
  Rédacteurs: 'redactores',
  'Typix - Détails du texte': 'Typix - Detalles del texto',
  Hongrois: 'Húngaro',
  'Bon pour livraison': 'Bueno para la entrega',
  'Au réel': 'En el mundo real',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebreo',
  'Nombre total de mots ajoutés :': 'Número total de palabras añadidas :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum': 'Debe ser inferior al número máximo',
  'Nom du texte': 'Nombre del texto',
  'Ajouter un rédacteur': 'Añadir un redactor',
  Ou: 'O',
  Utilisateurs: 'Usuarios',
  Heures: 'Horas',
  'Santé/Bien-être': 'Salud/Bienestar',
  'Interlingue ; Occidental': 'Interlingüe; Occidental',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Letón',
  Anglais: 'Inglés',
  Envoyer: 'Enviar',
  'Tableau de bord': 'Cuadro de mandos',
  'Demande de révision': 'Solicitud de revisión',
  'Résultat sémantique': 'Resultado semántico',
  'Chercher un rédacteur': 'Buscar un redactor',
  'Ce champs est obligatoire': 'Este campo es obligatorio',
  Rédaction: 'redactorial',
  'Modifier le client': 'Modificar el cliente',
  'Voulez-vous vraiment supprimer le rédacteur':
    '¿Realmente quieres borrar el redactor',
  Titre: 'Título',
  Langue: 'Idioma',
  'Mot clé': 'Palabras clave',
  'Les demandes suivantes ne seront pas prises en compte':
    'No se tendrán en cuenta las siguientes solicitudes',
  'délai dépassé': 'plazo incumplido',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Contrato existente, modifique la información',
  Igbo: 'Igbo',
  Yiddish: 'Yiddish',
  'Limburgan ; Limburger ; Limburgish': 'Limburgués; Limburgués; Limburgués',
  'Commandes validées': 'Pedidos validados',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Paso',
  'Energie/Environnement': 'Energía/Medio ambiente',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom': 'no debe incluir su nombre',
  'Typix - Fiche rédacteur': 'Typix - Archivo del redactor',
  'révisions effectuées en attentes de validation':
    'revisiones pendientes de validación',
  'Textes validés': 'Textos validados',
  Art: 'Arte',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Versátil',
  Sundanese: 'Sundanés',
  'Nb total de textes': 'Número total de textos',
  'Nombre de mots gérés': 'Número de palabras gestionadas',
  "L'émail": 'Esmalte',
  'Afficher les résultats': 'Resultados',
  'Frison occidental': 'Frisón occidental',
  'Modification effectuée avec succès': 'Modificación completada con éxito',
  'Balise titre': 'Etiqueta del título',
  'Typix - Mon profil': 'Typix - Mi perfil',
  'email invalide': 'correo no válido',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Por favor, añada una url para poder guardar el texto como borrador',
  Macédonien: 'Macedonio',
  'Votre candidature a bien été envoyée': 'Su solicitud ha sido enviada',
  'E-mail': 'Correo electrónico',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Añada o complete su contenido a continuación',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Añadir palabras clave con la frecuencia restante',
  'Fiche du site web': 'Ficha del sitio web',
  Contacter: 'Póngase en contacto con',
  Fidjien: 'Fiyi',
  Déconnexion: 'Desconexión',
  email: 'correo electrónico',
  'Nombre de textes': 'Número de textos',
  'Enregistrer et informer client': 'Registrar e informar a los clientes',
  'Date de validation': 'Fecha de validación',
  'Tonga (Îles Tonga)': 'Tonga (Islas Tonga)',
  Javanais: 'javanés',
  Portugais: 'Portugués',
  Biélorusse: 'Bielorruso',
  'Pour ajouter un nouveau texte': 'Para añadir un nuevo texto',
  'Enfant/Puéri': 'Niño/Puperio',
  'Mots clés': 'Palabras clave',
  Emploi: 'Empleo',
  'Voulez-vous vraiment supprimer la commande de':
    '¿Realmente desea eliminar el',
  Consultants: 'Consultores',
  Volapük: 'Volapük',
  Services: 'Servicios',
  'mot-clé (fréquence)': 'palabra clave (frecuencia)',
  'Ne plus afficher ce message': 'No volver a mostrar este mensaje',
  Consultant: 'Consultor',
  "Une erreur s'est produite": 'Se ha producido un error',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Al menos 8 caracteres con una letra mayúscula, un número, un carácter especial',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Francés',
  validées: 'validado',
  'Url du site': 'Url del sitio',
  Précédent: 'Anterior',
  'car celui-ci a déjà rédigé des textes':
    'porque este último ya ha redactado textos',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Borradores',
  Ido: 'Ido',
  'Contenu disponible': 'Contenido disponible',
  Enseignement: 'Enseñanza',
  'a été livré avec succès': 'se ha entregado con éxito',
  Chinois: 'Chino',
  Vietnamien: 'Vietnamita',
  'Nouveau client': 'Nuevo cliente',
  'Votre profil a été mis à jour avec succès.':
    'Su perfil se ha actualizado correctamente.',
  Consignes: 'Instrucciones',
  'Mot de passe': 'Contraseña',
  Annuler: 'Cancelar',
  Non: 'No',
  Nom: 'Nombre',
  Guarani: 'Guaraní',
  "il y'a des commandes liés à au site web":
    'hay pedidos relacionados con el sitio web',
  Score: 'Puntuación',
  'Mot de passe mis à jour avec succès': 'Contraseña actualizada correctamente',
  Latin: 'Latín',
  Technologie: 'Tecnología',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'se ha creado correctamente',
  Roumain: 'Rumano',
  Assamais: 'Asamés',
  'Khmer central': 'Jemer central',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Análisis/Límite',
  Grec: 'Griego',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Mis proyectos',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'palabra clave1 (2)\npalabra clave2 (1)\npalabra clave3 (1)\npalabra clave4 (2)\n...',
  'ajouter un texte': 'añadir un texto',
  'Détail de la prestation': 'Detalles del servicio',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Tiene la posibilidad de exportar el informe de uno o varios pedidos. Para exportar todos los pedidos de un cliente, basta con hacer clic en el icono amarillo de descarga',
  'Gestion des utilisateurs': 'Gestión de usuarios',
  'livré et inférieur au nombre de texte non affecté':
    'entregados y menor que el número de textos no asignados',
  'Date deadline validation client': 'Plazo de validación del cliente',
  Aymara: 'Aymara',
  Validées: 'Validado',
  Notes: 'Notas',
  'Nombre de GMB': 'Número de GMB',
  'résultats sur': 'resultados en',
  'Prénom / Nom du client': 'Nombre / Apellido del cliente',
  'Tout télécharger': 'Descargar todo',
  'Champs obligatoires': 'Campos obligatorios',
  Éditer: 'Editar',
  'Coller le texte initial dans le champs ci-dessous':
    'Pegue el texto original en el campo siguiente',
  'ID rédacteur': 'ID de redactor',
  Arménien: 'Armenio',
  'Grande distribution': 'Distribución a gran escala',
  Welsh: 'Galés',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Enriquecimiento semántico',
  'Détail de la prestation du contenu': 'Detalles de la entrega de contenidos',
  Noir: 'Negro',
  'Ajouter un texte': 'Añadir un texto',
  Traduction: 'Traducción',
  'Immo/Defisc': 'Propiedad/Fiscalidad',
  'Brief détaillé': 'Resumen detallado',
  'Séparez les mots-clés par un Enter .':
    'Separe las palabras clave con una Intro .',
  Prix: 'Premios',
  'mot de passe oublié': 'Contraseña olvidada',
  'Ndebele, South ; South Ndebele': 'Ndebele, Sur; Ndebele del Sur',
  'Demander une révision': 'Solicitar una revisión',
  'Demander une repasse globale': 'Solicitar una revisión integral',
  'Sotho, Southern': 'Sotho, Sur',
  'Le titre de la commande existe déjà': 'El título de la orden ya existe',
  'Félicitations !': '¡Felicidades!',
  Kurde: 'Kurdo',
  Adresse: 'Dirección',
  Espagnol: 'Español',
  Ndonga: 'Ndonga',
  'En retard': 'Tarde',
  'a été ajouté avec succès': 'se ha añadido correctamente',
  Commandes: 'Pedidos',
  'a été mis en brouillon': 'se ha redactado',
  'Rédacteur attribué': 'redactor asignado',
  Rechercher: 'Buscar en',
  'Nouvelle commande': 'Nuevo pedido',
  'Commandes non attribuées': 'Pedidos no asignados',
  'Le texte a été mis en brouillon': 'El texto ha sido redactado',
  'Contact edito': 'Contactar con edito',
  Langues: 'Idiomas',
  Orange: 'Naranja',
  'Nynorsk norvégien': 'Nynorsk noruego',
  Shona: 'Shona',
  Turkmène: 'Turkmen',
  'contenu repassé, en attente validation client':
    'contenido planchado, a la espera de la validación del cliente',
  'Nombre de mots rédigés': 'Número de palabras escritas',
  Malay: 'Malayo',
  'Nombre de Silo': 'Número de silos',
  Oui: 'Sí',
  Automobile: 'Automoción',
  Résultats: 'Resultados',
  Afar: 'Afar',
  'Tâche - id tâche': 'Tarea - id de tarea',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Este campo es obligatorio y debe ser mayor que 0',
  'Vous êtes sûr': '¿Estás seguro de que',
  'Réinitialiser votre mot de passe': 'Restablecer contraseña',
  Sindhi: 'Sindhi',
  Bengali: 'Bengalí',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, noruego; Bokmål noruego',
  'Nombre de Jours': 'Número de días',
  'Min/Max mots': 'Palabras mín./máx.',
  Slovène: 'Eslovenia',
  Site: 'Página web',
  'Sinhala ; Cingalais': 'Cingalés; Sinhalese',
  Bulgare: 'Búlgaro',
  'Liste des commandes': 'Lista de pedidos',
  "L'utilisateur": 'El usuario',
  Uzbek: 'Uzbeko',
  'Titre du texte': 'Título del texto',
  Postuler: 'Solicitar en',
  'Nb de textes par Silo': 'Nº de textos por silo',
  'Nombre total de mots (existants et ajoutés)':
    'Número total de palabras (existentes y añadidas)',
  'Max.': 'Max.',
  Beauté: 'Belleza',
  Humanitaire: 'Humanitario',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Búsqueda por autor o texto',
  'Balise meta-description': 'Etiqueta meta descripción',
  Malgache: 'Malgache',
  Tagalog: 'Tagalo',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Este campo debe ser mayor que 0 y menor que 1',
  'Le rédacteur': 'El redactor',
  'a été mis à jour avec succès': 'se ha actualizado correctamente',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Debe tener al menos 8 caracteres con una letra mayúscula, un número, un símbolo y no ser idéntico al nombre.',
  'textes en plus': 'más textos',
  'Format souhaité': 'Formato deseado',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Detalle de la línea de pedido',
  'Veuillez préciser la date': 'Especifique la fecha',
  Pali: 'Pali',
  Clients: 'Clientes',
  'Total facturé': 'Total facturado',
  'contenu validé par le client': 'contenido validado por el cliente',
  'Occitan (post 1500)': 'Occitano (después de 1500)',
  Japonais: 'Japonés',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': '¿De verdad quieres activar',
  Sanskrit: 'Sánscrito',
  'Toutes les notifications': 'Todas las notificaciones',
  Marathi: 'Marathi',
  Persan: 'Persa',
  'La commande pour le client': 'El pedido del cliente',
  'Intitulé de la commande': 'Título de la orden',
  Samoan: 'Samoa',
  Autres: 'Otros',
  Sport: 'Deporte',
  "il y'a des commandes liés au site web ":
    'hay pedidos relacionados con el sitio web',
  'Ajout de mots-clé avec la fréquence restante':
    'Añadir palabras clave con la frecuencia restante',
  'a été supprimé avec succès': 'ha sido eliminado con éxito',
  Postulé: 'Publicado en',
  Literie: 'Ropa de cama',
  'Édité par le client': 'Editado por el cliente',
  Cornouailles: 'Cornualles',
  Tigrinya: 'Tigriña',
  Textes: 'Textos',
  'Soumettre un nouveau texte': 'Enviar un nuevo texto',
  Téléphone: 'Teléfono',
  Deadline: 'Plazo',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Se ha producido un error. Por favor, compruebe su entrada o inténtelo más tarde.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Se envía la solicitud para añadir crédito',
  'Le rédacteur ': 'El redactor',
  'Demander des crédits': 'Solicitar un crédito',
  "Cette commande n'existe pas": 'Este comando no existe',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahití',
  Admins: 'Admins',
  'Mettez des mots-clés avec fréquence':
    'Establecer palabras clave con frecuencia',
  Notifications: 'Notificaciones',
  'en retard': 'tarde',
  "l'admin": 'el administrador',
  'Mail rédacteurs': 'redactores de correo',
  Amharique: 'Amárico',
  Societe: 'Empresa',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Número de textos añadidos',
  Société: 'Empresa',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'El texto se ha entregado correctamente',
  'Mettez votre texte': 'Pon tu texto',
  "Oups....! Une erreur s'est produite": '¡Oops....! Se ha producido un error',
  'Prénom/Nom': 'Nombre y apellidos',
  'Max doit être sup à Min': 'Max debe ser mayor que Min',
  'Importer un autre document': 'Importar otro documento',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Su solicitud ha caducado ... por favor haga otra',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Por favor, corrija el formato de su brecha semántica para guardar el texto como borrador',
  Exemple: 'Ejemplo',
  Slovaque: 'Eslovaquia',
  Finance: 'Finanzas',
  Réactiver: 'Reactivar',
  'Nb textes': 'Nº de textos',
  'Intitulé commande': 'Título del pedido',
  Supprimer: 'Borrar',
  Brouillon: 'Borrador',
  'Mots clés restants': 'Palabras clave restantes',
  Date: 'Fecha',
  Rôle: 'Papel',
  'Ce champs doit être sup à 0': 'Este campo debe ser mayor que 0',
  'Maison/Déco': 'Hogar/Decoración',
  'Vous avez': 'Usted tiene',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Esta línea de comandos no puede borrarse porque parte de su texto ha sido entregado',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'compruebe su correo electrónico y haga clic en el enlace para continuar.',
  Sarde: 'Cerdeña',
  'Note rédacteur': 'Nota del redactor',
  'Uighur ; Uyghur': 'Uigur; uigur',
  Name: 'Nombre',
  'Commandes intelligentes': 'pedidos smart',
  'Commandes avec révision': 'pedidos smart + review',
  'Type de contenu': 'Tipo de contenido',
  'Fiche produit': 'Ficha de producto',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Escritura premium',
  'Rédaction avec révision': 'Escritura smart + review',
  'Rédaction intelligente': 'Escritura smart',
  PREMIUM: 'PREMIUM',
  Type: 'Tipo',
  Editer: 'Editar',
  'Tous les types': 'Todos los tipos',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Facturas procesadas',
  'Factures à valider': 'Facturas por validar',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Imposible generar la factura. Por favor, inténtelo de nuevo',
  'Récapitulatif de facturation': 'Resumen de facturación',
  'Demander une modification': 'Solicitar una modificación',
  'Valider et envoyer la facture': 'Validar y enviar la factura',
  'Demande de modification': 'Solicitud de modificación',
  'La facture a été validé avec succès!':
    '¡La factura se ha validado correctamente!',
  "Oups un erreur c'est produit!": '¡Ups! Se ha producido un error',
  'Nombre de texte': 'Número de textos',
  'Nombre de mot par texte :': 'Número de palabras por texto:',
  Paiement: 'Pago',
  Virement: 'Transferencia bancaria',
  Délai: 'Plazo',
  'Référence de votre facture': 'Referencia de su factura',
  'Coût au mot': 'Costo por palabra',
  'Montant global de la commande': 'Monto total del pedido',
  'Votre facture': 'Su factura',
  'Importer votre facture': 'Importar su factura',
  'Pas de fichier disponible': 'No hay archivo disponible',
  Télécharger: 'Descargar',
  Relancer: 'Reenviar',
  'Générer la facture': 'Generar la factura',
  Payée: 'Pagada',
  'En cours de paiement': 'En proceso de pago',
  'En cours de validation': 'En proceso de validación',
  'A envoyer': 'Por enviar',
  'Modification demandée': 'Modificación solicitada',
  'Référence de facture est obligatoire': 'Se requiere referencia de factura',
  'Le coût au mot doit être un nombre':
    'El costo por palabra debe ser un número',
  'Le coût au mot doit être un nombre positif':
    'El costo por palabra debe ser un número positivo',
  'Le coût au mot est obligatoire': 'Se requiere el costo por palabra',
  'Le montant global de la commande doit être un nombre':
    'El monto total del pedido debe ser un número',
  'Le montant global de la commande doit être un nombre positif':
    'El monto total del pedido debe ser un número positivo',
  'Le montant global de la commande est obligatoire':
    'Se requiere el monto total del pedido',
  'Bill reference existe déja , veuillez réessayer':
    'La referencia de la factura ya existe, por favor, inténtelo de nuevo',
  'La facture a été envoyée avec succès!':
    '¡La factura se ha enviado correctamente!',

  'Montant estimé': 'Monto estimado',
  Action: 'Acción',
  Facture: 'Factura',
  Statut: 'Estado',
  Factures: 'Facturas',
  'Valider la facture': 'Validar la factura',
  'La relance a été envoyée avec succès !':
    '¡El recordatorio se ha enviado con éxito!',
  'La demande de modification a été envoyée avec succès !':
    '¡La solicitud de modificación se ha enviado con éxito!',
  'Le cout est trop élevé': 'El costo es demasiado alto',
  'Le montant global de la commande est trop élevé ':
    'El monto total del pedido es demasiado alto',
  jours: 'días',
  'Factures à envoyer': 'Facturas a enviar',
  'Valider le paiement': 'Validar pago',
  'Générer une facture': 'Generar una factura',
  'Montant rédacteur': 'Monto del redactor',
  'Montant estimé par Typix': 'Monto estimado por Typix',
  'Voir la modification': 'Ver modificación',

  'Tag de Titre': 'Etiqueta del título',
  'Meta description': 'Meta descripción',
  'Nom du produit': 'Nombre del producto',
  Avantages: 'Ventajas',
  'Description détaillée': 'Descripción detallada',
  'En cours de modification': 'En proceso de modificación',
  'Mes Factures': 'Mis facturas',
  'En attente de validation': 'En espera de validación',
  'les valeurs négatives ne sont pas autorisées':
    'No se permiten valores negativos.',
  'Texte Seulement': 'Solo texto',
  'Traduction Seulement': 'Solo traducción',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'El recordatorio se puede enviar 20 días después del envío de la factura',
  Instructions: 'Instrucciones',
  'Votre avis pour la commande': 'Tu opinión sobre el pedido',
  'Votre évaluation est précieuse pour nous ! Merci !':
    '¡Tu evaluación es valiosa para nosotros! ¡Gracias!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'Se produjo un error al enviar tu evaluación. Por favor, inténtalo de nuevo más tarde.',
  Evaluer: 'Evaluar',
  Commentaire: 'Comentario',
  'Votre commantaire': 'Tu comentario',
  Note: 'Calificación',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Tu feedback es valioso para nosotros y nos ayuda a mejorar nuestros servicios.',
  'Verifier la facture': 'Verificar la factura',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Su opinión es valiosa para nosotros y nos ayuda a mejorar nuestros servicios. ¡Gracias por compartir su evaluación con nosotros!',
  'Notes rédacteur': 'Notas del redactor',
  'Avis client': 'Opiniones de clientes',
  'Pas de commantaire': 'Sin comentario',
  "Qu'avez vous pensé de votre commande?": '¿Qué opinas de tu pedido?',
  'Evaluation de la commande': 'Evaluación del pedido',
  'Rédiger un commentaire': 'Redactar un comentario',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Contribuye a permitirnos ofrecerte servicios de redacción de la más alta calidad compartiendo tus comentarios.',
  'Que pouvons-nous améliorer?': '¿Qué podemos mejorar?',
  Continuer: 'Continuar',
  'Le dernier texte a été validé avec succès':
    'El último texto ha sido validado con éxito',
  'Rechercher par rédacteur, commandes, et montant':
    'Buscar por redactor, pedidos y monto',
  obligatoire: 'obligatorio',
  'Note admin': 'Nota del administrador',
  'Note client': 'Nota del cliente',
  Partie: 'Parte',
  'Vous venez de valider tous les contenus rédigés par ':
    'Acabas de validar todo el contenido escrito por ',
  "Qu'en avez vous pensé": '¿Qué opinas al respecto',
  'La facture a été générée avec succès !':
    '¡La factura se ha generado con éxito!',
  'La taille du titre et/ou la méta-description est trop courte':
    'La longitud del título y/o la meta descripción es demasiado corta',

  'Demande de révision globale': 'Requiere una revisión global',
  Valider: 'Validar',
  'Obligatoire en cas d’optimisation ou traduction':
    'Obligatorio en caso de optimización o traducción',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Tu comentario...',
  'Ex : "Contenus de très bonne qualité':
    'Ej.: "Contenido de muy buena calidad"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    '¿Podría considerar asignar a este/a redactor/a para futuros pedidos, por favor?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Este botón es para informar al cliente que el texto original ya contenía relleno de palabras clave',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Editar el texto',
  Ajouter: 'Agregar',
  'Demande examen': 'Solicitud de examen',
  'Recherche de mots clés à haute densité': 'Investigación de keyword stuffing',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    '¡Ups, parece que hay keyword stuffing en tu texto: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    '¡Ups, parece que hay keyword stuffing (demasiadas ocurrencias de una o más palabras clave) en el texto original.',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Por favor, asegúrese de que la densidad de palabras clave esté optimizada para SEO (un máximo de una ocurrencia cada 50 palabras).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'Para ello, por favor no edite el texto existente y envíe amablemente una solicitud de examen al soporte.',
  'Votre demande a été bien envoyée !':
    '¡Su solicitud ha sido enviada con éxito!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'Hubo un problema al enviar su solicitud.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Por favor, espere el análisis de keyword stuffing.',
  'Inserez le maillage interne': 'Vložte vnútornú sieť',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Gracias por asegurarse de que la densidad de palabras clave esté optimizada para el SEO (una ocurrencia máxima cada 50 palabras).\nSi nota un término que puede tener múltiples significados (Ejemplo: Formar / Formación / Forma o Colchón / Acolchado), puede solicitar una revisión al soporte.',
  'Mots clés à haute densité dans le texte actuel':
    'Palabras clave de alta densidad en el texto actual',
  'Mots clés à haute densidad dans le texte initial':
    'Palabras clave de alta densidad en el texto inicial',
  'Texte en cours de rédaction !': 'Texto en proceso de redacción !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    '¿Estás seguro/a de querer confirmar el texto? Asegúrate primero de haber guardado los cambios.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    '¡Información de última hora! Tu saldo de créditos se agotará pronto :( Para anticiparte, no dudes en solicitar más créditos ahora para completar tu pedido.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    '¿Quiere confirmar la URL actual o modificarla?',
  'Veuillez fournir une URL valide': 'Por favor, proporcione una URL válida.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Por favor, indique a continuación la URL de la página donde se ha integrado el contenido:',
  'Contenu en ligne': 'Contenido en línea',
  'Télécharger le document de révision': 'Descargar el documento de revisión',
  'Date d’envoi': 'Fecha de envío',
  Information: 'Información',
  Enregistrer: 'Guardar',
  'Textes en ligne': 'Textos en línea',
  'Livrer les textes': 'Entregar los textos',
  Indisponible: 'No disponible',
  Disponible: 'Disponible',
  'Les textes ont été livrés avec succès.':
    'Los textos han sido entregados con éxito.',
  'Le texte a été livré avec succès.': 'El texto ha sido entregado con éxito.',
  'Les textes ont été validés avec succès.':
    'Los textos han sido validados con éxito.',
  'Le texte a été validé avec succès.': 'El texto ha sido validado con éxito.',
  'Voulez-vous vraiment valider les Textes de la commande':
    '¿Realmente quieres validar los textos del pedido?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    '¿Realmente quieres entregar los textos del pedido?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Vill du verkligen leverera beställningens texter?',
  'Fiche produit longue': 'Ficha de producto larga',
  'Fiche produit courte': 'Ficha de producto corta',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Un texto es demasiado largo y no se puede exportar a Excel.',
  'Mettre un texte en brouillon en livré':
    'Cambiar un texto de borrador a entregado',
  'Remettre un texte validé à livré': 'Cambiar un texto validado a entregado',
  'Invalider le texte': 'Invalidar el texto',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'Quisiera cambiar mi contraseña: ¿cómo lo hago?',
  'Comment mettre un contenu en bon pour livraison ?':
    '¿Cómo poner el contenido listo para entrega?',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    '¿No puedo guardar mi contenido como "borrador"',
  'Typix n’a pas enregistré mes dernières modifications':
    'Typix no guardó mis últimos cambios',
  'Comment rédiger une création de contenu ?':
    '¿Cómo redactar una creación de contenido?',
  'Comment rédiger une optimisation de contenu ?':
    '¿Cómo redactar una optimización de contenido?',
  'Je ne vois plus ma commande – que faire ?':
    '¿Ya no veo mi pedido – qué hacer?',
  'Comment générer une facture ?': '¿Cómo generar una factura?',
  'Je ne peux pas generer ma facture : que faire ?':
    'No puedo generar mi factura: ¿qué hacer?',
  'Tuto process Typix global': 'Proceso global Typix - tutorial',
  'Tuto mise en ligne des contenus': 'Cómo subir contenido - tutorial',
  'Modification mot de passe': 'Cambio de contraseña',
  'Le test ne me convient pas': 'La prueba no me conviene',
  'Comment valider un contenu dans Typix ?':
    '¿Cómo validar contenido en Typix?',
  'Tuto Facturation': 'Tutorial de facturación',
  'Le statut du contenu a bien été modifié':
    'El estado del contenido ha sido modificado con éxito',
  'Le statut du contenu a bien été modifié':
    'El estado del contenido ha sido modificado con éxito',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'La brecha semántica de esta palabra clave aún no ha sido validada, póngase en contacto con el soporte de Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Esta palabra clave no existe en MONETORING, verifique con el soporte de Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Recuperar el hueco',
  'Le statut du contenu a bien été modifié':
    'El estado del contenido ha sido modificado con éxito',
  'Le statut du contenu a bien été modifié':
    'El estado del contenido ha sido modificado con éxito',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'La brecha semántica de esta palabra clave aún no ha sido validada, póngase en contacto con el soporte de Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Esta palabra clave no existe en MONETORING, verifique con el soporte de Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Recuperar el hueco',
  'Traduction premium': 'Traducción premium',
  'Rédaction et/ou Traduction intelligente':
    'Redacción y/o Traducción inteligente',
  'Rédaction et/ou Traduction premium': 'Redacción y/o Traducción premium',
  'Rédaction et/ou Traduction avec révision':
    'Redacción y/o Traducción con revisión',
  'Rédaction et/ou Traduction premium': 'Redacción y/o Traducción premium',
  'Rédaction et/ou Traduction avec révision':
    'Redacción y/o Traducción con revisión',
  'Rédaction et/ou Traduction intelligente':
    'Redacción y/o Traducción inteligente',
  'Traduction avec révision': 'Traducción con revisión',
  'Traduction intelligente': 'Traducción inteligente',
};
