export const greekTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Οι γραμμές εντολών ενημερώθηκαν επιτυχώς!',
  'Le texte a été enregistré avec succès': 'Το κείμενο καταχωρήθηκε επιτυχώς',
  'Η μετάφραση έχει καταγραφεί με επιτυχία': 'Το κείμενο καταχωρήθηκε επιτυχώς',
  'Nombre de mots': 'Αριθμός λέξεων',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Όνομα/επώνυμο του πελάτη',
  Bashkir: 'Μπασκίρ',
  "La date doit être ultérieure à aujourd'hui.":
    'Η ημερομηνία πρέπει να είναι μεταγενέστερη της σημερινής.',
  'Contenu rédigé': 'Περιεχόμενο γραμμένο',
  'Nom/prénom': 'Επώνυμο/όνομα',
  'Mot de passe incorrect': 'Λανθασμένος κωδικός πρόσβασης',
  Institutions: 'Ιδρύματα',
  'Veuillez donner un descriptif au document téléchargé':
    'Παρακαλώ δώστε μια περιγραφή του κατεβασμένου εγγράφου',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Επόμενο',
  Kannada: 'Κανάντα',
  'Séparer les mots clés par un "enter"':
    'Διαχωρίστε τις λέξεις-κλειδιά με ένα "enter".',
  Allemand: 'Γερμανικά',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'ένα email που περιέχει έναν σύνδεσμο επαναφοράς κωδικού πρόσβασης έχει σταλεί στο email σας',
  'Chef edito': 'Αρχισυντάκτης',
  Croate: 'Κροατικά',
  'Nb de textes': 'Αριθμός κειμένων',
  Violet: 'Βιολέτα',
  'Ce champs doit être sup ou égale nombre de texte':
    'Το πεδίο αυτό πρέπει να είναι μεγαλύτερο ή ίσο με τον αριθμό των κειμένων',
  'a été activé avec succès': 'έχει ενεργοποιηθεί επιτυχώς',
  Népalais: 'Νεπάλ',
  'Nombre de FAQ': 'Αριθμός Συχνών Ερωτήσεων',
  Urdu: 'Ουρντού',
  'Analyses restantes': 'Υπόλοιπες αναλύσεις',
  Indonésien: 'Ινδονησιακή',
  Breton: 'Μπρέτον',
  Livrés: 'Παραδίδεται',
  Associations: 'Ενώσεις',
  'Le client': 'Ο πελάτης',
  'Révision demandée': 'Ζητήθηκε αναθεώρηση',
  "Une erreur s'est produite, veuillez réessayer":
    'Εμφανίστηκε σφάλμα, παρακαλώ δοκιμάστε ξανά',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Μη εξουσιοδοτημένη ενημέρωση καθώς τα κείμενα έχουν παραδοθεί',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Για να προσθέσετε κείμενο (βελτιστοποίηση υπάρχοντος περιεχομένου)',
  Gaélique: 'Gaelic',
  'La ligne de commande pour': 'Η γραμμή εντολών για',
  'Business/Management': 'Επιχειρήσεις/Διοίκηση',
  Serbe: 'Σέρβικα',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Παρακαλείστε να αναφέρετε παρακάτω τα αιτήματά σας για τροποποιήσεις, λαμβάνοντας υπόψη τους όρους σιδερώματος.',
  'rédacteurs trouvés': 'βρέθηκαν συγγραφείς',
  'Kirghiz ; Kirghiz': 'Kirghiz; Kirghiz',
  Différence: 'Διαφορά',
  'en cours': 'σε εξέλιξη',
  'Sami du Nord': 'Βόρειοι Σάμι',
  Comparer: 'Σύγκριση',
  Lituanien: 'Λιθουανικά',
  'Typix - Mon tableau de bord': 'Typix - Το ταμπλό μου',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Πρέπει να αποτελείται από τουλάχιστον 8 χαρακτήρες με κεφαλαίο γράμμα, αριθμό, ειδικό χαρακτήρα και να μην ταυτίζεται με το όνομα.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Παρακαλώ εισάγετε έγκυρα πεδία',
  Basque: 'Βάσκοι',
  'Exporter .csv': 'Εξαγωγή .csv',
  'Confirmer le nouveau mot de passe': 'Επιβεβαίωση νέου κωδικού πρόσβασης',
  "Pas d'options": 'Δεν υπάρχουν επιλογές',
  'Banque/Assurance': 'Τράπεζες/Ασφάλειες',
  'Vos demandes': 'Τα αιτήματά σας',
  'Coût d\u2019achat': 'Κόστος αγοράς',
  Tamil: 'Ταμίλ',
  'Le texte a été supprimé avec succès': 'Το κείμενο διαγράφηκε επιτυχώς',
  'Mes Commandes': 'Οι παραγγελίες μου',
  Zoulou: 'Ζουλού',
  Afficher: 'Προβολή',
  Texte: 'Κείμενο',
  Islandais: 'Ισλανδική',
  Russe: 'Ρωσική',
  'Tous les textes': 'Όλα τα κείμενα',
  Czech: 'Τσεχική',
  'Comparaison hors ligne': 'Σύγκριση εκτός σύνδεσης',
  Téléphonie: 'Τηλεφωνία',
  'Valider le texte': 'Επικύρωση του κειμένου',
  'Votre text ne de doit pas dépasser 500 mots':
    'Το κείμενό σας δεν πρέπει να υπερβαίνει τις 500 λέξεις',
  'Coquilles (double espace, « s »\u2026)': 'Κοχύλια (διπλό διάστημα, "s"...)',
  'le consultant': 'ο σύμβουλος',
  Coréen: 'Κορεάτικα',
  'Nb de Silo': 'Αριθμός σιλό',
  Validés: 'Επικυρωμένο',
  'non attribuées': 'μη κατανεμημένο',
  Néerlandais: 'Ολλανδικά',
  'Voulez-vous vraiment supprimer': 'Θέλετε πραγματικά να διαγράψετε',
  Rundi: 'Rundi',
  Regional: 'Περιφερειακή',
  'Compte client': 'Λογαριασμός πελάτη',
  Global: 'Παγκόσμια',
  'Tous les rôles': 'Όλοι οι ρόλοι',
  Musique: 'Μουσική',
  Perso: 'Προσωπικό',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Φαίνεται ότι δεν έχετε πλέον πίστωση ανάλυσης',
  Bambara: 'Bambara',
  Sortir: 'Βγες έξω.',
  optionel: 'προαιρετικό',
  'Temps restant': 'Υπολειπόμενος χρόνος',
  Ukrainien: 'Ουκρανικό',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa, Chewa, Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Το αίτημά σας για επανεξέταση έχει σταλεί.',
  'Typix - Créer rédacteur': 'Typix - Δημιουργήστε έναν επεξεργαστή',
  "n'existe pas": 'δεν υπάρχει',
  Hindi: 'Χίντι',
  'ne peut pas être supprimé car il est lié à des sites web':
    'δεν μπορεί να διαγραφεί καθώς συνδέεται με ιστότοπους',
  'Commandes en cours': 'Παραγγελίες σε εξέλιξη',
  'Textes livrés': 'Παραδοθέντα κείμενα',
  Validation: 'Επικύρωση',
  'Non attribuées': 'Δεν έχει χορηγηθεί',
  'Voulez-vous vraiment désactiver': 'Θέλετε πραγματικά να απενεργοποιήσετε',
  'Catalan ; Valencien': 'Καταλανικά, Βαλένθια',
  'Nombre de textes restants': 'Αριθμός κειμένων που απομένουν',
  'Ne doit pas contenir votre nom': 'Δεν πρέπει να περιέχει το όνομά σας',
  Documentation: 'Τεκμηρίωση',
  Kazakh: 'Καζακστάν',
  'Numéro invalide': 'Μη έγκυρος αριθμός',
  'Veuillez saisir un texte ou importer un fichier.':
    'Εισάγετε ένα κείμενο ή εισάγετε ένα αρχείο.',
  'Nb de FAQ': 'Αριθμός Συχνών Ερωτήσεων',
  Analyser: 'Ανάλυση',
  Turc: 'Τουρκικά',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Οποιοδήποτε αίτημα που δεν προσδιορίζεται στη συνοπτική έκθεση',
  Wallon: 'Walloon',
  Birmane: 'Βιρμανίας',
  Irlandais: 'Ιρλανδική',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Επαναφέρετε τον κωδικό πρόσβασής σας',
  Cuisine: 'Κουζίνα',
  'Haïtien ; créole haïtien': 'Αϊτινή; Αϊτινή κρεολική',
  'Vous ne pouvez pas supprimer le rédacteur':
    'Δεν μπορείτε να διαγράψετε τον επεξεργαστή',
  Validé: 'Επικυρωμένο',
  "cet email n'existe pas": 'αυτό το email δεν υπάρχει',
  Historique: 'Ιστορία',
  Confirmer: 'Επιβεβαίωση',
  'à cocher si le nombre de mots est différent par texte':
    'πρέπει να τσεκαριστεί εάν ο αριθμός των λέξεων είναι διαφορετικός ανά κείμενο',
  'Fiche du site web ': 'Φύλλο ιστοσελίδας',
  Désactiver: 'Απενεργοποίηση',
  'Travaux/BTP': 'Έργα/κατασκευές',
  'La fiche du website': 'Το φύλλο της ιστοσελίδας',
  'Si le contenu ne respecte pas les règles du brief':
    'Εάν το περιεχόμενο δεν τηρεί τους κανόνες της σύντομης περιγραφής',
  Norvégien: 'Νορβηγικό',
  '0 rédacteurs': '0 συντάκτες',
  indisponible: 'μη διαθέσιμο',
  Venda: 'Venda',
  Maori: 'Μαορί',
  'Textes revisés': 'Αναθεωρημένα κείμενα',
  'Status texte': 'Κατάσταση κειμένου',
  'textes livrés en attente de validation':
    'κείμενα που παραδόθηκαν εν αναμονή επικύρωσης',
  'Typix - Mon Profile': 'Typix - Το προφίλ μου',
  'le client': 'ο πελάτης',
  Romanche: 'Romanche',
  'URL du site': 'URL του ιστότοπου',
  'nouvelles notifications': 'νέες ειδοποιήσεις',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Μαλδίβες',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Είστε σίγουροι ότι θέλετε να αποθηκεύσετε το κείμενο',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Προσθέστε ένα αρχικό κείμενο για να αποθηκεύσετε το κείμενο ως προσχέδιο.',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Η σύμβαση για τον πελάτη',
  rédacteurs: 'συντάκτες',
  'Ce champ est obligatoire': 'Το πεδίο αυτό είναι υποχρεωτικό',
  'Tarif/Mot': 'Ποσοστό/Λέξη',
  Tadjik: 'Τατζικιστάν',
  'Réf devis': 'Αναφορά εισαγωγικού σημειώματος',
  'Rechercher un rédacteur': 'Αναζήτηση συγγραφέα',
  Albanais: 'Αλβανικά',
  Galicien: 'Γαλικία',
  Disponibilité: 'Διαθεσιμότητα',
  'Les deux mots de passe ne sonts pas identiques':
    'Οι δύο κωδικοί πρόσβασης δεν είναι πανομοιότυποι',
  Tibétain: 'Θιβέτ',
  'Typix - Gestion des utilisateurs': 'Typix - Διαχείριση χρηστών',
  'Ossétien ; Ossétique': 'Οσσετιανά; Οσετικά',
  Tswana: 'Tswana',
  'Ajouter un client': 'Προσθέστε έναν πελάτη',
  'Séparez les mots-clés par un " Enter ".':
    'Διαχωρίστε τις λέξεις-κλειδιά με ένα "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Θέματα',
  'Nb mots/texte': 'Αριθμός λέξεων/κειμένου',
  Commerce: 'Εμπόριο',
  'Voir les commandes': 'Δείτε τις παραγγελίες',
  'Nombre de mots par texte': 'Αριθμός λέξεων ανά κείμενο',
  'Lien drive...': 'Οδηγός συνδέσμου...',
  'Hollandais ; Flamand': 'Ολλανδικά; Φλαμανδικά',
  'Mon profil': 'Το προφίλ μου',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Το πεδίο αυτό πρέπει να είναι μεγαλύτερο του 0, διότι η τιμολόγηση δεν είναι πραγματική.',
  'Le texte a été validé avec succès': 'Το κείμενο έχει επικυρωθεί επιτυχώς',
  Jeux: 'Παιχνίδια',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Θέλετε πραγματικά να αφαιρέσετε τη γραμμή εντολών για το',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, North; Ndebele North',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Εκκλησιαστικά σλαβικά; Παλαιά σλαβικά; Εκκλησιαστικά σλαβικά; Παλαιά βουλγαρικά; Παλαιά εκκλησιαστικά σλαβικά',
  Swahili: 'Σουαχίλι',
  Voyage: 'Ταξίδι',
  'Nombre de mot à ajouter': 'Αριθμός λέξεων προς προσθήκη',
  'Liste des rédacteurs': 'Κατάλογος συντακτών',
  'le rédacteur': 'ο εκδότης',
  Kanuri: 'Kanuri',
  rédacteur: 'συντάκτης',
  Tatar: 'Τατάρ',
  'Roumain ; Moldave ; Moldovan': 'Ρουμανικά, Μολδαβικά',
  'Le texte initial de': 'Το αρχικό κείμενο του',
  'Mail clients': 'Ταχυδρομείο πελάτη',
  'Le contrat de': 'Το συμβόλαιο της',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'τουλάχιστον ένα πεζό και ένα κεφαλαίο γράμμα, έναν ειδικό χαρακτήρα και έναν αριθμό',
  Transport: 'Μεταφορά',
  'Nombre total de mots (existants et ajoutés) :':
    'Συνολικός αριθμός λέξεων (υφιστάμενων και προστιθέμενων) :',
  'Rechercher par mail, prénom, nom, site...':
    'Αναζήτηση με email, όνομα, επώνυμο, τοποθεσία...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Αριθμός λέξεων προς προσθήκη (Ελάχιστο/Μέγιστο) :',
  Compte: 'Λογαριασμός',
  'Tourisme/Loisirs': 'Τουρισμός/Αναψυχή',
  'Nouveau texte': 'Νέο κείμενο',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; Γροιλανδικά',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Ουπς...! Υπάρχουν ένα ή περισσότερα σφάλματα στο παράθυρο του σημασιολογικού κενού. Παρακαλούμε ελέγξτε το περιεχόμενό του για να το διορθώσετε και υποβάλετε ξανά το κείμενό σας.',
  'ne peut pas être supprimé pour le moment':
    'δεν μπορεί να διαγραφεί αυτή τη στιγμή',
  'Le texte': 'Το κείμενο',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Υπάρχει ήδη ένας χρήστης με το email',
  Cree: 'Cree',
  Prénom: 'Όνομα',
  'commandes prête': 'παραγγελίες έτοιμες',
  Réinitialiser: 'Επαναφορά',
  'Veuillez fournir une date valide': 'Παρακαλούμε δώστε μια έγκυρη ημερομηνία',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Λουξεμβουργιανά; Letzeburgesch',
  Suédois: 'Σουηδικά',
  'Confirmer le mot de passe': 'Επιβεβαίωση κωδικού πρόσβασης',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Το πεδίο αυτό είναι υποχρεωτικό και μπορεί να περιέχει μόνο αριθμούς',
  Sciences: 'Επιστήμη',
  Tchétchène: 'Τσετσενία',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'είναι ο μοναδικός πελάτης για μια τοποθεσία, πρέπει να προστεθούν και άλλοι για να αφαιρεθεί',
  'Le mot de passe de confirmation ne correspond pas':
    'Ο κωδικός επιβεβαίωσης δεν ταιριάζει',
  Swati: 'Swati',
  Danois: 'Δανικά',
  Sauvegarder: 'Αποθήκευση',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Παρακαλούμε αλλάξτε τις ακόλουθες πληροφορίες: τίτλος ή/και λέξη-κλειδί',
  'importer un document': 'εισαγωγή εγγράφου',
  Maltais: 'Μαλτέζικα',
  'Nb de mots': 'Αριθμός λέξεων',
  'Les données fournies sont invalides': 'Τα παρεχόμενα δεδομένα είναι άκυρα',
  'Livraison estimée': 'Εκτιμώμενη παράδοση',
  'Grec moderne (1453-)': 'Νέα Ελληνικά (1453-)',
  Avestan: 'Αβεστάν',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Συνολικός αριθμός προστιθέμενων λέξεων',
  'le contenu présente des inexactitudes':
    'το περιεχόμενο περιέχει ανακρίβειες',
  Finnois: 'Φινλανδική',
  'Ajouter un admin': 'Προσθέστε έναν διαχειριστή',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Τροποποίηση του συμβούλου',
  'Liste des clients': 'Κατάλογος πελατών',
  'Nom client': 'Όνομα πελάτη',
  Vert: 'Πράσινο',
  'Mots clés manquants': 'Λείπουν λέξεις-κλειδιά',
  Lao: 'Λάος',
  Somali: 'Σομαλοί',
  'Nb de silo par texte': 'Αριθμός σιλό ανά κείμενο',
  Italien: 'Ιταλικά',
  'La commande a été mis à jour avec succès !':
    'Η παραγγελία ενημερώθηκε επιτυχώς!',
  Bosniaque: 'Βοσνιακή',
  'Thématiques d\u2019expertise': 'Θέματα εμπειρογνωμοσύνης',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Αυτός είναι ο κωδικός πρόσβασης που ξεχάσατε',
  'a été désactivé avec succès': 'έχει απενεργοποιηθεί επιτυχώς',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Υποβολή αίτησης για την παραγγελία',
  'mot-clé': 'λέξη-κλειδί',
  Abkhazien: 'Αμπχαζία',
  Mongolien: 'Μογγολικά',
  'Typix - Fiche site web': 'Typix - Ιστοσελίδα',
  'Mail envoyé': 'Αποστολή αλληλογραφίας',
  Géorgien: 'Γεωργιανή',
  'Si des phrases ne sont pas dans un français correct':
    'Εάν οι προτάσεις δεν είναι στα σωστά γαλλικά',
  'En cours': 'Σε εξέλιξη',
  voir: 'βλέπε',
  "Date d'ajout": 'Ημερομηνία προσθήκης',
  'Tarif au mot': 'Ποσοστό ανά λέξη',
  Thai: 'Ταϊλάνδης',
  'Détails du texte': 'Λεπτομέρειες του κειμένου',
  'Commandes en retard': 'Καθυστερημένες παραγγελίες',
  disponible: 'διαθέσιμο',
  Droit: 'Δεξιά',
  Azerbaïdjanais: 'Αζερμπαϊτζάν',
  'Ajouter un consultant': 'Προσθήκη συμβούλου',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Κατά την αναπαραγωγή περιεχομένου, θα υποστηρίζονται μόνο τα ακόλουθα στοιχεία',
  'doit comprendre au moins 8 charactères':
    'πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες',
  'Titre de la commande': 'Τίτλος της παραγγελίας',
  'Langues bihari': 'Γλώσσες Bihari',
  'Livraison estimé au': 'Εκτιμώμενη παράδοση από',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Ο αριθμός των κειμένων της παραγγελίας δεν μπορεί να είναι μικρότερος από τον αριθμό των κειμένων που έχουν ανατεθεί στον/στις συγγραφέα/ες. Αλλάξτε πρώτα τις πληροφορίες στη γραμμή εντολών',
  'Date de livraison': 'Ημερομηνία παράδοσης',
  'Voulez-vous vraiment supprimer le contrat de':
    'Θέλετε πραγματικά να καταργήσετε το',
  'Facturation au réel': 'Πραγματική τιμολόγηση',
  'Gap sémantique': 'Σημασιολογικό κενό',
  Estonien: 'Εσθονικά',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Μέγιστο κόστος αγοράς/λέξη',
  'Détail de la commande': 'Λεπτομέρειες παραγγελίας',
  'Export en cours de téléchargement\u2026': 'Η εξαγωγή ανεβαίνει...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Το πεδίο αυτό είναι υποχρεωτικό και πρέπει να είναι μικρότερο ή ίσο με τον Συνολικό αριθμό κειμένων της παραγγελίας.',
  Arabe: 'Αραβικά',
  Bislama: 'Bislama',
  'Mail client': 'Ταχυδρομείο πελάτη',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Τίτλος, λέξεις-κλειδιά, url, ετικέτα, μετα-περιγραφή)',
  'Typix - Nouvelle commande': 'Typix - Νέα παραγγελία',
  'Révision en attente': 'Αναθεώρηση σε εξέλιξη',
  'Le consultant': 'Ο σύμβουλος',
  Entreprise: 'Εταιρεία',
  Aragonais: 'Αραγονική',
  Kashmiri: 'Κασμίρι',
  'a été activé': 'έχει ενεργοποιηθεί',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Διεθνής Ένωση Βοηθητικών Γλωσσών)',
  Actualités: 'Νέα',
  'Votre décision a été prise en compte avec succès':
    'Η απόφασή σας ελήφθη επιτυχώς υπόψη',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'περιεχόμενο που περιμένει επαλήθευση από τον πελάτη',
  'Fiche du rédacteur': 'Αρχείο του συντάκτη',
  Outillage: 'Εργαλεία',
  'Typix - Détails de la commande': 'Typix - Λεπτομέρειες παραγγελίας',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Προσθέστε ή συμπληρώστε το περιεχόμενό σας παρακάτω:',
  Ewe: 'Προβατίνα',
  'contenu en attente de repasse rédacteur': 'content pending replay editor',
  'La commande a été supprimé avec succès': 'Η παραγγελία διαγράφηκε επιτυχώς',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Γαλλικές εκφράσεις ή φράσεις που δεν σας ταιριάζουν',
  Corse: 'Κορσική',
  Modifier: 'Επεξεργασία',
  'Nb de GMB': 'Αριθμός GMBs',
  Oromo: 'Oromo',
  Polish: 'Πολωνικά',
  'Insérez votre gap sémantique avec l’occurrence':
    'Εισάγετε το σημασιολογικό σας κενό με την εμφάνιση',
  'Nb de textes à optimiser': 'Αριθμός κειμένων προς βελτιστοποίηση',
  Mode: 'Λειτουργία',
  Akan: 'Akan',
  'Révisions effectués': 'Πραγματοποιηθείσες αναθεωρήσεις',
  'Modifier l\u2019admin': 'Επεξεργασία του διαχειριστή',
  'Télécharger le document': 'Κατεβάστε το έγγραφο',
  Hausa: 'Χάουσα',
  'Notes rédacteurs': 'Σημειώσεις των συντακτών',
  Quechua: 'Quechua',
  Féroïen: 'Φερόες',
  'Modifier le rédacteur': 'Αλλαγή του συντάκτη',
  Mail: 'Ταχυδρομείο',
  Rédacteurs: 'Συγγραφείς',
  'Typix - Détails du texte': 'Typix - Λεπτομέρειες κειμένου',
  Hongrois: 'Ουγγρικό',
  'Bon pour livraison': 'Καλό για παράδοση',
  'Au réel': 'Στον πραγματικό κόσμο',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Εβραϊκά',
  'Nombre total de mots ajoutés :': 'Συνολικός αριθμός προστιθέμενων λέξεων :',
  Komi: 'Κόμι',
  'Doit être inférieur au nombre maximum':
    'Πρέπει να είναι μικρότερος από τον μέγιστο αριθμό',
  'Nom du texte': 'Όνομα του κειμένου',
  'Ajouter un rédacteur': 'Προσθέστε έναν συντάκτη',
  Ou: 'Ή',
  Utilisateurs: 'Χρήστες',
  Heures: 'Ώρες',
  'Santé/Bien-être': 'Υγεία/Υγεία',
  'Interlingue ; Occidental': 'Διαγλωσσική; Δυτική',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Λετονία',
  Anglais: 'Αγγλικά',
  Envoyer: 'Στείλτε',
  'Tableau de bord': 'Ταμπλό',
  'Demande de révision': 'Αίτημα επανεξέτασης',
  'Résultat sémantique': 'Σημασιολογικό αποτέλεσμα',
  'Chercher un rédacteur': 'Αναζήτηση συντάκτη',
  'Ce champs est obligatoire': 'Το πεδίο αυτό είναι υποχρεωτικό',
  Rédaction: 'Σύνταξη',
  'Modifier le client': 'Τροποποιήστε τον πελάτη',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Θέλετε πραγματικά να διαγράψετε τον επεξεργαστή',
  Titre: 'Τίτλος',
  Langue: 'Γλώσσα',
  'Les demandes suivantes ne seront pas prises en compte':
    'Οι ακόλουθες αιτήσεις δεν θα ληφθούν υπόψη',
  'délai dépassé': 'χαμένη προθεσμία',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Υφιστάμενη σύμβαση, παρακαλούμε τροποποιήστε τις πληροφορίες',
  Igbo: 'Igbo',
  Yiddish: 'Γίντις',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan, Limburger, Limburgish',
  'Commandes validées': 'Επικυρωμένες παραγγελίες',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Βήμα',
  'Energie/Environnement': 'Ενέργεια/Περιβάλλον',
  Min: 'Min',
  Gujarati: 'Γκουτζαράτι',
  'ne doit pas inclure votre nom/prénom':
    'δεν πρέπει να περιλαμβάνει το όνομά σας/το μικρό σας όνομα',
  'Typix - Fiche rédacteur': 'Typix - Αρχείο συγγραφέα',
  'révisions effectuées en attentes de validation':
    'αναθεωρήσεις που έγιναν εν αναμονή επικύρωσης',
  'Textes validés': 'Επικυρωμένα κείμενα',
  Art: 'Τέχνη',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Ευέλικτο',
  Sundanese: 'Sundanese',
  'Nb total de textes': 'Συνολικός αριθμός κειμένων',
  'Nombre de mots gérés': 'Αριθμός διαχειριζόμενων λέξεων',
  "L'émail": 'Σμάλτο',
  'Afficher les résultats': 'Εμφάνιση αποτελεσμάτων',
  'Frison occidental': 'Δυτική φριζική',
  'Modification effectuée avec succès': 'Η τροποποίηση ολοκληρώθηκε επιτυχώς',
  'Balise titre': 'Ετικέτα τίτλου',
  'Typix - Mon profil': 'Typix - Το προφίλ μου',
  'email invalide': 'άκυρο email',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Παρακαλώ προσθέστε ένα url ώστε να μπορείτε να αποθηκεύσετε το κείμενο ως προσχέδιο',
  Macédonien: 'Μακεδονικό',
  'Votre candidature a bien été envoyée': 'Η αίτησή σας έχει αποσταλεί',
  'E-mail': 'E-mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Προσθέστε ή συμπληρώστε το περιεχόμενό σας παρακάτω',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Προσθήκη λέξεων-κλειδιών με εναπομείνασα συχνότητα',
  'Fiche du site web': 'Φύλλο ιστοσελίδας',
  Contacter: 'Επικοινωνία',
  Fidjien: 'Φίτζι',
  Déconnexion: 'Αποσύνδεση',
  email: 'email',
  'Nombre de textes': 'Αριθμός κειμένων',
  'Enregistrer et informer client': 'Εγγραφή και ενημέρωση των πελατών',
  'Date de validation': 'Ημερομηνία επικύρωσης',
  'Tonga (Îles Tonga)': 'Τόνγκα (Νησιά Τόνγκα)',
  Javanais: 'Τζαβανέζικα',
  Portugais: 'Πορτογαλικά',
  Biélorusse: 'Λευκορωσία',
  'Pour ajouter un nouveau texte': 'Για να προσθέσετε ένα νέο κείμενο',
  'Enfant/Puéri': 'Παιδί/Puperium',
  'Mots clés': 'Λέξεις κλειδιά',
  Emploi: 'Απασχόληση',
  'Voulez-vous vraiment supprimer la commande de':
    'Θέλετε πραγματικά να αφαιρέσετε το',
  'Mot clé': 'Λέξη-κλειδί',
  Consultants: 'Σύμβουλοι',
  Volapük: 'Volapük',
  Services: 'Υπηρεσίες',
  'mot-clé (fréquence)': 'λέξη-κλειδί (συχνότητα)',
  'Ne plus afficher ce message': 'Μην εμφανίσετε ξανά αυτό το μήνυμα',
  Consultant: 'Σύμβουλος',
  "Une erreur s'est produite": 'Εμφανίστηκε σφάλμα',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Τουλάχιστον 8 χαρακτήρες με ένα κεφαλαίο γράμμα, έναν αριθμό, έναν ειδικό χαρακτήρα',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Γαλλικά',
  validées: 'επικυρωμένο',
  'Url du site': 'Url του ιστότοπου',
  Précédent: 'Προηγούμενο',
  'car celui-ci a déjà rédigé des textes':
    'διότι η τελευταία έχει ήδη συντάξει κείμενα',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Σχέδια',
  Ido: 'Ido',
  'Contenu disponible': 'Διαθέσιμο περιεχόμενο',
  Enseignement: 'Διδασκαλία',
  'a été livré avec succès': 'έχει παραδοθεί με επιτυχία',
  Chinois: 'Κινέζικα',
  Vietnamien: 'Βιετναμέζικα',
  'Nouveau client': 'Νέος πελάτης',
  'Votre profil a été mis à jour avec succès.':
    'Το προφίλ σας ενημερώθηκε με επιτυχία.',
  Consignes: 'Οδηγίες',
  'Mot de passe': 'Κωδικός πρόσβασης',
  Annuler: 'Ακύρωση',
  Non: 'Όχι',
  Nom: 'Όνομα',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'υπάρχουν παραγγελίες που σχετίζονται με την ιστοσελίδα',
  Score: 'Βαθμολογία',
  'Mot de passe mis à jour avec succès':
    'Ο κωδικός πρόσβασης ενημερώθηκε επιτυχώς',
  Latin: 'Λατινικά',
  Technologie: 'Τεχνολογία',
  Internet: 'Διαδίκτυο',
  Nauru: 'Ναούρου',
  'a été créé avec succès': 'έχει δημιουργηθεί με επιτυχία',
  Roumain: 'Ρουμανικά',
  Assamais: 'Assamese',
  'Khmer central': 'Κεντρική Χμερ',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Ανάλυση/Όριο',
  Grec: 'Ελληνική',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Τα έργα μου',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'λέξη-κλειδί1 (2)\nλέξη-κλειδί2 (1)\nΛέξη-κλειδί3 (1)\nλέξη-κλειδί4 (2)\n...',
  'ajouter un texte': 'προσθέστε ένα κείμενο',
  'Détail de la prestation': 'Λεπτομέρειες της υπηρεσίας',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Έχετε τη δυνατότητα να εξάγετε την έκθεση μιας ή περισσότερων παραγγελιών. Για να εξαγάγετε όλες τις παραγγελίες ενός πελάτη, αρκεί να κάνετε κλικ στο κίτρινο εικονίδιο λήψης.',
  'Gestion des utilisateurs': 'Διαχείριση χρηστών',
  'livré et inférieur au nombre de texte non affecté':
    'παραδοθέντα και λιγότερα από τον αριθμό των μη εκχωρηθέντων κειμένων',
  'Date deadline validation client': 'Προθεσμία επικύρωσης από τον πελάτη',
  Aymara: 'Aymara',
  Validées: 'Επικυρωμένο',
  Notes: 'Σημειώσεις',
  'Nombre de GMB': 'Αριθμός GMBs',
  'résultats sur': 'αποτελέσματα σε',
  'Prénom / Nom du client': 'Όνομα / Όνομα του πελάτη',
  'Tout télécharger': 'Κατεβάστε όλα',
  'Champs obligatoires': 'Υποχρεωτικά πεδία',
  Éditer: 'Επεξεργασία',
  'Coller le texte initial dans le champs ci-dessous':
    'Επικολλήστε το αρχικό κείμενο στο παρακάτω πεδίο',
  'ID rédacteur': 'Αναγνωριστικό συντάκτη',
  Arménien: 'Αρμένιοι',
  'Grande distribution': 'Διανομή μεγάλης κλίμακας',
  Welsh: 'Ουαλικά',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Σημασιολογικός εμπλουτισμός',
  'Détail de la prestation du contenu':
    'Λεπτομέρειες της παράδοσης περιεχομένου',
  Noir: 'Μαύρο',
  'Ajouter un texte': 'Προσθέστε ένα κείμενο',
  Traduction: 'Μετάφραση',
  'Immo/Defisc': 'Ακίνητα/Φόροι',
  'Brief détaillé': 'Λεπτομερής ενημέρωση',
  'Séparez les mots-clés par un Enter .':
    'Διαχωρίστε τις λέξεις-κλειδιά με ένα Enter .',
  Prix: 'Βραβεία',
  'mot de passe oublié': 'Ξεχάσατε τον κωδικό πρόσβασης',
  'Ndebele, South ; South Ndebele': 'Ndebele, South; South Ndebele',
  'Demander une révision': 'Αίτηση αναθεώρησης',
  'Demander une repasse globale': 'Ζητήστε μια συνολική αναθεώρησ',
  'Sotho, Southern': 'Sotho, Νότια',
  'Le titre de la commande existe déjà': 'Ο τίτλος της παραγγελίας υπάρχει ήδη',
  'Félicitations !': 'Συγχαρητήρια!',
  Kurde: 'Κούρδος',
  Adresse: 'Διεύθυνση',
  Espagnol: 'Ισπανικά',
  Ndonga: 'Ndonga',
  'En retard': 'Αργά',
  'a été ajouté avec succès': 'έχει προστεθεί επιτυχώς',
  Commandes: 'Έλεγχοι',
  'a été mis en brouillon': 'έχει συνταχθεί',
  'Rédacteur attribué': 'Εκτεθειμένος συντάκτης',
  Rechercher: 'Αναζήτηση',
  'Nouvelle commande': 'Νέα παραγγελία',
  'Commandes non attribuées': 'Μη κατανεμημένες παραγγελίες',
  'Le texte a été mis en brouillon': 'Το κείμενο έχει συνταχθεί',
  'Contact edito': 'Επικοινωνήστε με το edito',
  Langues: 'Γλώσσες',
  Orange: 'Πορτοκαλί',
  'Nynorsk norvégien': 'Νορβηγικά Nynorsk',
  Shona: 'Shona',
  Turkmène: 'Τουρκμενιστάν',
  'contenu repassé, en attente validation client':
    'σιδερωμένο περιεχόμενο, εν αναμονή της επικύρωσης από τον πελάτη',
  'Nombre de mots rédigés': 'Αριθμός γραμμένων λέξεων',
  Malay: 'Μαλαισία',
  'Nombre de Silo': 'Αριθμός σιλό',
  Oui: 'Ναι',
  Automobile: 'Αυτοκίνητο',
  Résultats: 'Αποτελέσματα',
  Afar: 'Afar',
  'Tâche - id tâche': 'Εργασία - αναγνωριστικό εργασίας',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Το πεδίο αυτό είναι υποχρεωτικό και πρέπει να είναι μεγαλύτερο του 0',
  'Vous êtes sûr': 'Είσαι σίγουρος',
  'Réinitialiser votre mot de passe': 'Επαναφέρετε τον κωδικό πρόσβασής σας',
  Sindhi: 'Sindhi',
  Bengali: 'Μπενγκάλι',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, νορβηγικό, νορβηγικό Bokmål',
  'Nombre de Jours': 'Αριθμός ημερών',
  'Min/Max mots': 'Ελάχιστες/Μέγιστες λέξεις',
  Slovène: 'Σλοβενική',
  Site: 'Ιστοσελίδα',
  'Sinhala ; Cingalais': 'Σινχάλα; Σινχάλη',
  Bulgare: 'Βουλγαρική',
  'Liste des commandes': 'Κατάλογος παραγγελιών',
  "L'utilisateur": 'Ο χρήστης',
  Uzbek: 'Ουζμπεκιστάν',
  'Titre du texte': 'Τίτλος του κειμένου',
  Postuler: 'Εφαρμόστε στο',
  'Nb de textes par Silo': 'Αριθμός κειμένων ανά σιλό',
  'Nombre total de mots (existants et ajoutés)':
    'Συνολικός αριθμός λέξεων (υπάρχουσες και προστιθέμενες)',
  'Max.': 'Max.',
  Beauté: 'Ομορφιά',
  Humanitaire: 'Ανθρωπιστική βοήθεια',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Αναζήτηση ανά συντάκτη ή κείμενο',
  'Balise meta-description': 'Meta description tag',
  Malgache: 'Μαδαγασκάρη',
  Tagalog: 'Ταγκαλόγκ',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Το πεδίο αυτό πρέπει να είναι μεγαλύτερο του 0 και μικρότερο του 1',
  'Le rédacteur': 'Ο εκδότης',
  'a été mis à jour avec succès': 'έχει ενημερωθεί επιτυχώς',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Πρέπει να αποτελείται από τουλάχιστον 8 χαρακτήρες με κεφαλαίο γράμμα, αριθμό, σύμβολο και να μην ταυτίζεται με το όνομα.',
  'textes en plus': 'περισσότερα κείμενα',
  'Format souhaité': 'Επιθυμητή μορφή',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Λεπτομέρεια γραμμής παραγγελίας',
  'Veuillez préciser la date': 'Παρακαλώ καθορίστε την ημερομηνία',
  Pali: 'Pali',
  Clients: 'Πελάτες',
  'Total facturé': 'Σύνολο τιμολογίου',
  'contenu validé par le client': 'περιεχόμενο επικυρωμένο από τον πελάτη',
  'Occitan (post 1500)': 'Occitan (μετά το 1500)',
  Japonais: 'Ιαπωνικά',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Θέλετε πραγματικά να ενεργοποιήσετε',
  Sanskrit: 'Σανσκριτικά',
  'Toutes les notifications': 'Όλες οι ειδοποιήσεις',
  Marathi: 'Μαράθι',
  Persan: 'Περσικό',
  'La commande pour le client': 'Η παραγγελία για τον πελάτη',
  'Intitulé de la commande': 'Τίτλος της παραγγελίας',
  Samoan: 'Σαμόα',
  Autres: 'Άλλα',
  Sport: 'Αθλητισμός',
  "il y'a des commandes liés au site web ":
    'υπάρχουν παραγγελίες που σχετίζονται με την ιστοσελίδα',
  'Ajout de mots-clé avec la fréquence restante':
    'Προσθήκη λέξεων-κλειδιών με εναπομείνασα συχνότητα',
  'a été supprimé avec succès': 'έχει αφαιρεθεί επιτυχώς',
  Postulé: 'Αναρτήθηκε',
  Literie: 'Κλινοσκεπάσματα',
  'Édité par le client': 'Επεξεργασία από τον πελάτη',
  Cornouailles: 'Κορνουάλη',
  Tigrinya: 'Tigrinya',
  Textes: 'Κείμενα',
  'Soumettre un nouveau texte': 'Υποβολή νέου κειμένου',
  Téléphone: 'Τηλέφωνο',
  Deadline: 'Προθεσμία',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Εμφανίστηκε σφάλμα. Παρακαλούμε ελέγξτε την καταχώρηση ή προσπαθήστε ξανά αργότερα.',
  Marshallais: 'Μάρσαλ',
  "La demande d'ajout de crédit est envoyée":
    'Το αίτημα για την προσθήκη πίστωσης αποστέλλεται',
  'Le rédacteur ': 'Ο εκδότης',
  'Demander des crédits': 'Υποβολή αίτησης για πίστωση',
  "Cette commande n'existe pas": 'Αυτή η εντολή δεν υπάρχει',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Ταϊτινή',
  Admins: 'Διαχειριστές',
  'Mettez des mots-clés avec fréquence': 'Ορίστε λέξεις-κλειδιά με συχνότητα',
  Notifications: 'Ειδοποιήσεις',
  'en retard': 'αργά',
  "l'admin": 'ο διαχειριστής',
  'Mail rédacteurs': 'Συντάκτες ηλεκτρονικού ταχυδρομείου',
  Amharique: 'Αμχαρικά',
  Societe: 'Εταιρεία',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Αριθμός προστιθέμενων κειμένων',
  Société: 'Εταιρεία',
  Yoruba: 'Γιορούμπα',
  'Le texte a été livré avec succès': 'Το κείμενο παραδόθηκε επιτυχώς',
  'Mettez votre texte': 'Βάλτε το κείμενό σας',
  "Oups....! Une erreur s'est produite": 'Oops....! Εμφανίστηκε σφάλμα',
  'Prénom/Nom': 'Όνομα/επώνυμο',
  'Max doit être sup à Min': 'Το Max πρέπει να είναι μεγαλύτερο από το Min',
  'Importer un autre document': 'Εισαγωγή άλλου εγγράφου',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Η αίτησή σας έχει λήξει ... παρακαλούμε κάντε άλλη μία',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Παρακαλώ διορθώστε τη μορφή του σημασιολογικού σας κενού προκειμένου να αποθηκεύσετε το κείμενο ως προσχέδιο',
  Exemple: 'Παράδειγμα',
  Slovaque: 'Σλοβακική',
  Finance: 'Οικονομικά',
  Réactiver: 'Ενεργοποίηση',
  'Nb textes': 'Αριθμός κειμένων',
  'Intitulé commande': 'Τίτλος παραγγελίας',
  Supprimer: 'Διαγραφή',
  Brouillon: 'Σχέδιο',
  'Mots clés restants': 'Υπόλοιπες λέξεις-κλειδιά',
  Date: 'Ημερομηνία',
  Rôle: 'Ρόλος',
  'Ce champs doit être sup à 0':
    'Το πεδίο αυτό πρέπει να είναι μεγαλύτερο του 0',
  'Maison/Déco': 'Σπίτι/Διακόσμηση',
  'Vous avez': 'Έχετε',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Αυτή η γραμμή εντολών δεν μπορεί να διαγραφεί επειδή μέρος του κειμένου της έχει παραδοθεί',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'ελέγξτε το email σας και κάντε κλικ στο σύνδεσμο για να συνεχίσετε!',
  Sarde: 'Σαρδηνία',
  'Note rédacteur': 'Σημείωση του συντάκτη',
  'Uighur ; Uyghur': 'Ουιγούρος; Ουιγούρος',
  Name: 'Όνομα',
  'Commandes intelligentes': 'Smart παραγγελίες',
  'Commandes avec révision': 'Smart + review παραγγελίες',
  'Type de contenu': 'Τύπος περιεχομένου',
  'Fiche produit': 'Προϊόν',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Primium σύνταξη κειμένου',
  'Rédaction avec révision': 'Smart + review σύνταξη κειμένου',
  'Rédaction intelligente': 'Smart σύνταξη κειμένου',
  PREMIUM: 'PREMIUM',
  Type: 'Τύπος',
  Editer: 'Επεξεργασία',
  'Tous les types': 'Όλοι οι τύποι',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Επεξεργασία τιμολογίων',
  'Factures à valider': 'Τιμολόγια προς επικύρωση',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Αδυναμία δημιουργίας του τιμολογίου! Παρακαλώ δοκιμάστε ξανά',
  'Récapitulatif de facturation': 'Συνοπτική αναφορά τιμολόγησης',
  'Demander une modification': 'Αίτηση για τροποποίηση',
  'Valider et envoyer la facture': 'Επικύρωση και αποστολή του τιμολογίου',
  'Demande de modification': 'Αίτηση τροποποίησης',
  'La facture a été validé avec succès!':
    'Το τιμολόγιο επικυρώθηκε με επιτυχία!',
  "Oups un erreur c'est produit!": 'Ωχ, συνέβη ένα σφάλμα!',
  'Nombre de texte': 'Αριθμός κειμένων',
  'Nombre de mot par texte :': 'Αριθμός λέξεων ανά κείμενο:',
  Paiement: 'Πληρωμή',
  Virement: 'Μεταφορά χρημάτων',
  Délai: 'Προθεσμία',
  'Référence de votre facture': 'Αναφορά του τιμολογίου σας',
  'Coût au mot': 'Κόστος ανά λέξη',
  'Montant global de la commande': 'Συνολικό ποσό της παραγγελίας',
  'Votre facture': 'Το τιμολόγιό σας',
  'Importer votre facture': 'Εισαγωγή του τιμολογίου σας',
  'Pas de fichier disponible': 'Δεν υπάρχει διαθέσιμο αρχείο',
  Télécharger: 'Λήψη',
  Relancer: 'Επανασταλτικό',
  'Générer la facture': 'Δημιουργία του τιμολογίου',
  Payée: 'Εξοφλήθη',
  'En cours de paiement': 'Εκκρεμής πληρωμή',
  'En cours de validation': 'Σε διαδικασία επικύρωσης',
  'A envoyer': 'Προς αποστολή',
  'Modification demandée': 'Ζητήθηκε τροποποίηση',
  'Référence de facture est obligatoire': 'Απαιτείται αναφορά τιμολογίου',
  'Le coût au mot doit être un nombre':
    'Το κόστος ανά λέξη πρέπει να είναι αριθμός',
  'Le coût au mot doit être un nombre positif':
    'Το κόστος ανά λέξη πρέπει να είναι θετικός αριθμός',
  'Le coût au mot est obligatoire': 'Το κόστος ανά λέξη είναι απαραίτητο',
  'Le montant global de la commande doit être un nombre':
    'Το συνολικό ποσό της παραγγελίας πρέπει να είναι αριθμός',
  'Le montant global de la commande doit être un nombre positif':
    'Το συνολικό ποσό της παραγγελίας πρέπει να είναι θετικός αριθμός',
  'Le montant global de la commande est obligatoire':
    'Το συνολικό ποσό της παραγγελίας είναι απαραίτητο',
  'Bill reference existe déja , veuillez réessayer':
    'Η αναφορά του τιμολογίου υπάρχει ήδη, παρακαλώ δοκιμάστε ξανά',
  'La facture a été envoyée avec succès!': 'Το τιμολόγιο στάλθηκε με επιτυχία!',

  'Montant estimé': 'Εκτιμώμενο ποσό',
  Action: 'Δράση',
  Facture: 'Τιμολόγιο',
  Statut: 'Κατάσταση',
  Factures: 'τιμολόγια',
  'Valider la facture': 'Επιβεβαίωση του τιμολογίου',
  'La relance a été envoyée avec succès !':
    'Η υπενθύμιση στάλθηκε με επιτυχία!',
  'La demande de modification a été envoyée avec succès !':
    'Το αίτημα τροποποίησης στάλθηκε με επιτυχία!',
  'Le cout est trop élevé': 'Ο κόστος είναι υπερβολικά υψηλός',
  'Le montant global de la commande est trop élevé ':
    'Το συνολικό ποσό της παραγγελίας είναι υπερβολικά υψηλό',
  jours: 'μέρες',
  'Factures à envoyer': 'Τιμολόγια για αποστολή',
  'Valider le paiement': 'Επιβεβαίωση πληρωμής',
  'Générer une facture': 'Δημιουργία τιμολογίου',
  'Montant rédacteur': 'Ποσό συγγραφέα',
  'Montant estimé par Typix': 'Εκτιμώμενο ποσό από το Typix',
  'Voir la modification': 'Προβολή τροποποίησης',

  'Tag de Titre': 'Ετικέτα τίτλου',
  'Meta description': 'Μετα-περιγραφή',
  'Nom du produit': 'Όνομα προϊόντος',
  Avantages: 'Πλεονεκτήματα',
  'Description détaillée': 'Λεπτομερής περιγραφή',
  'En cours de modification': 'Σε εξέλιξη αλλαγών',
  'Mes Factures': 'Οι λογαριασμοί μου',
  'En attente de validation': 'Σε αναμονή έγκρισης',
  'les valeurs négatives ne sont pas autorisées':
    'Οι αρνητικές τιμές δεν επιτρέπονται.',
  'Texte Seulement': 'Μόνο κείμενο',
  'Traduction Seulement': 'Μόνο μετάφραση',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'Η υπενθύμιση μπορεί να αποσταλεί 20 ημέρες μετά την υποβολή του τιμολογίου',
  Instructions: 'Οδηγίες',
  'Votre avis pour la commande': 'Η γνώμη σας για την παραγγελία',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Η αξιολόγησή σας είναι πολύτιμη για εμάς! Ευχαριστούμε!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'Παρουσιάστηκε σφάλμα κατά την υποβολή της αξιολόγησής σας. Παρακαλώ δοκιμάστε ξανά αργότερα!',
  Evaluer: 'Αξιολογήστε',
  Commentaire: 'Σχόλιο',
  'Votre commantaire': 'Το σχόλιό σας',
  Note: 'Βαθμολογία',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Η ανατροφοδότησή σας είναι πολύτιμη για εμάς και μας βοηθά να βελτιώσουμε τις υπηρεσίες μας.',
  'Verifier la facture': 'Επαληθεύστε το τιμολόγιο',
  'Texte + Traduction enregistré avec succès':
    'Κείμενο + Μετάφραση έχουν καταγραφεί με επιτυχία',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Η ανατροφοδότησή σας είναι πολύτιμη για εμάς και μας βοηθά να βελτιώσουμε τις υπηρεσίες μας. Ευχαριστούμε που μοιράζεστε την αξιολόγησή σας μαζί μας!',
  'Notes rédacteur': 'Σημειώσεις συντάκτη',
  'Avis client': 'Αξιολόγηση πελάτη',
  'Pas de commantaire': 'Χωρίς σχόλιο',
  "Qu'avez vous pensé de votre commande?":
    'Τι σκέφτηκατε για την παραγγελία σας;',
  'Evaluation de la commande': 'Αξιολόγηση παραγγελίας',
  'Rédiger un commentaire': 'Συντάξτε ένα σχόλιο',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Συνεισφέρετε ώστε να σας προσφέρουμε υπηρεσίες υψηλότερης ποιότητας, μοιραζόμενοι τα σχόλιά σας.',
  'Que pouvons-nous améliorer?': 'Τι μπορούμε να βελτιώσουμε;',
  Continuer: 'Συνέχεια',
  'Le dernier texte a été validé avec succès':
    'Ο τελευταίος κείμενος εγκρίθηκε με επιτυχία',
  'Rechercher par rédacteur, commandes, et montant':
    'Αναζήτηση ανά συντάκτη, παραγγελίες και ποσό',
  obligatoire: 'υποχρεωτικό',
  'Note admin': 'Σημείωση διαχειριστή',
  'Note client': 'Σημείωση πελάτη',
  Partie: 'Μέρος',
  'Vous venez de valider tous les contenus rédigés par ':
    'Μόλις εγκρίνατε όλο το περιεχόμενο που έχει συντάξει ',
  "Qu'en avez vous pensé": "Τι νομίζετε γι' αυτό",
  'La facture a été générée avec succès !':
    'Το τιμολόγιο δημιουργήθηκε με επιτυχία!',
  'La taille du titre et/ou la méta-description est trop courte':
    'Το μήκος του τίτλου και/ή η μετα-περιγραφή είναι πολύ μικρά',

  'Demande de révision globale': 'Απαιτεί μια γενική αναθεώρηση',
  Valider: 'Επιβεβαιώνω',
  'Obligatoire en cas d’optimisation ou traduction':
    'Απαιτείται σε περίπτωση βελτιστοποίησης ή μετάφρασης',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Ο σχολιασμός σας...',
  'Ex : "Contenus de très bonne qualité':
    'Παράδειγμα: "Πολύ καλής ποιότητας περιεχόμενο"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Μπορείτε παρακαλώ να αναθέσετε αυτόν τον συγγραφέα για μελλοντικές παραγγελίες;',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Αυτό το κουμπί είναι για να ενημερώσει τον πελάτη ότι το αρχικό κείμενο είχε ήδη υπερβολική χρήση λέξεων-κλειδιών',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Επεξεργασία κειμένου',
  Ajouter: 'Προσθήκη',
  'Demande examen': 'Αίτηση εξέτασης',
  'Recherche de mots clés à haute densité': 'Έρευνα γεμίσματος λέξεων-κλειδιών',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Ωχ, φαίνεται ότι υπάρχει keyword stuffing στο κείμενό σας: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Ωχ, φαίνεται ότι υπάρχει keyword stuffing (πάρα πολλές εμφανίσεις ενός ή περισσότερων λέξεων-κλειδιών) στον αρχικό κείμενο.',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Παρακαλούμε ελέγξτε ότι η πυκνότητα των λέξεων-κλειδιών είναι βελτιστοποιημένη για το SEO (ένα μέγιστο εμφάνιση κάθε 50 λέξεις).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'Για αυτό, παρακαλώ μην επεξεργαστείτε τον υπάρχοντα κείμενο και στείλτε ευγενικά αίτημα εξέτασης στην υποστήριξη.',
  'Votre demande a été bien envoyée !':
    'Το αίτημά σας έχει σταλεί με επιτυχία!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'Υπήρξε ένα πρόβλημα κατά την αποστολή του αιτήματός σας.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Παρακαλούμε περιμένετε για την ανάλυση του keyword stuffing.',
  'Inserez le maillage interne': 'Εισαγάγετε το εσωτερικό δίκτυο',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Βεβαιωθείτε ότι η πυκνότητα των λέξεων-κλειδιών είναι βελτιστοποιημένη για το SEO (μέγιστη μία εμφάνιση κάθε 50 λέξεις).\nΕάν αντιμετωπίσετε έναν όρο που μπορεί να έχει πολλαπλές σημασίες (Παράδειγμα: Προηγούμενος / Εκπαίδευση / Μορφή ή Στρώμα / Πάπλωμα), μπορείτε να ζητήσετε μια αναθεώρηση από την υποστήριξη.',
  'Mots clés à haute densité dans le texte actuel':
    'Λέξεις-κλειδιά υψηλής πυκνότητας στο τρέχον κείμενο',
  'Mots clés à haute densité dans le texte initial':
    'Λέξεις-κλειδιά υψηλής πυκνότητας στο αρχικό κείμενο',
  'Texte en cours de rédaction !': 'Κείμενο υπό σύνταξη !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Είστε σίγουρος/η ότι θέλετε να επιβεβαιώσετε το κείμενο; Βεβαιωθείτε πρώτα ότι έχετε αποθηκεύσει τις αλλαγές.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Τελευταία στιγμή πληροφορίες! Το υπόλοιπο των πιστώσεών σας θα εξαντληθεί σύντομα :( Για να προβλέψετε, μην διστάσετε να ζητήσετε περισσότερες πιστώσεις τώρα για να ολοκληρώσετε την παραγγελία σας.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Θέλετε να επιβεβαιώσετε την τρέχουσα διεύθυνση URL ή να την αλλάξετε;',
  'Veuillez fournir une URL valide': 'Παρακαλώ δώστε μια έγκυρη διεύθυνση URL.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Παρακαλούμε ενημερώστε παρακάτω τη διεύθυνση URL της σελίδας όπου έχει ενσωματωθεί το περιεχόμενο:',
  'Contenu en ligne': 'Διαδικτυακό περιεχόμενο',
  'Télécharger le document de révision': 'Λήψη του εγγράφου αναθεώρησης',
  'Date d’envoi': 'Ημερομηνία αποστολής',
  Information: 'Πληροφορία',
  Enregistrer: 'Αποθήκευση',
  'Textes en ligne': 'Κείμενα στο διαδίκτυο',
  'Livrer les textes': 'Παράδοση των κειμένων',
  Indisponible: 'Μη διαθέσιμο',
  Disponible: 'Διαθέσιμο',
  'Les textes ont été livrés avec succès.':
    'Τα κείμενα παραδόθηκαν με επιτυχία.',
  'Le texte a été livré avec succès.': 'Το κείμενο παραδόθηκε με επιτυχία.',
  'Les textes ont été validés avec succès.':
    'Τα κείμενα επικυρώθηκαν με επιτυχία.',
  'Le texte a été validé avec succès.': 'Το κείμενο επικυρώθηκε με επιτυχία.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Θέλετε πραγματικά να επικυρώσετε τα κείμενα της παραγγελίας;',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Θέλετε πραγματικά να παραδώσετε τα κείμενα της παραγγελίας;',
  'Fiche produit longue': 'Μακρύ φύλλο προϊόντος',
  'Fiche produit courte': 'Σύντομο φύλλο προϊόντος',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Ένα κείμενο είναι πολύ μεγάλο και δεν μπορεί να εξαχθεί στο Excel.',
  'Mettre un texte en brouillon en livré':
    'Μετατρέψτε ένα κείμενο από πρόχειρο σε παραδοθέν',
  'Remettre un texte validé à livré':
    'Μετατρέψτε ένα επικυρωμένο κείμενο σε παραδοθέν',
  'Invalider le texte': 'Ακυρώστε το κείμενο',
  'Je souhaiterai modifier mon mot de passe : comment faire ?':
    'Θα ήθελα να αλλάξω τον κωδικό πρόσβασής μου: πώς το κάνω;',
  'Comment mettre un contenu en bon pour livraison ?':
    'Πώς να ρυθμίσετε το περιεχόμενο για παράδοση;',
  'Je ne peux pas mettre mon contenu en « brouillon »':
    'Δεν μπορώ να αποθηκεύσω το περιεχόμενό μου ως "πρόχειρο";',
  'Typix n’a pas enregistré mes dernières modifications':
    'Δεν αποθήκευσε το Typix τις τελευταίες αλλαγές μου;',
  'Comment rédiger une création de contenu ?':
    'Πώς να γράψετε δημιουργία περιεχομένου;',
  'Comment rédiger une optimisation de contenu ?':
    'Πώς να γράψετε βελτιστοποίηση περιεχομένου;',
  'Je ne vois plus ma commande – que faire ?':
    'Δεν βλέπω πλέον την παραγγελία μου – τι να κάνω;',
  'Comment générer une facture ?': 'Πώς να δημιουργήσετε τιμολόγιο;',
  'Je ne peux pas generer ma facture : que faire ?':
    'Δεν μπορώ να δημιουργήσω το τιμολόγιό μου: τι να κάνω;',
  'Tuto process Typix global': 'Παγκόσμια διαδικασία Typix - οδηγός',
  'Tuto mise en ligne des contenus': 'Πώς να ανεβάσετε περιεχόμενο - οδηγός',
  'Modification mot de passe': 'Αλλαγή κωδικού πρόσβασης',
  'Le test ne me convient pas': 'Η δοκιμή δεν μου ταιριάζει',
  'Comment valider un contenu dans Typix ?':
    'Πώς να επικυρώσετε περιεχόμενο στο Typix?',
  'Tuto Facturation': 'Οδηγός τιμολόγησης',
  'Le statut du contenu a bien été modifié':
    'Η κατάσταση του περιεχομένου έχει τροποποιηθεί επιτυχώς',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Το σέμαντικ κενό αυτής της λέξης-κλειδί δεν έχει ακόμη επικυρωθεί, παρακαλούμε επικοινωνήστε με την υποστήριξη Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Αυτή η λέξη-κλειδί δεν υπάρχει στο MONETORING, παρακαλούμε ελέγξτε με την υποστήριξη Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Ανάκτηση του κενού',
  'Le statut du contenu a bien été modifié':
    'Η κατάσταση του περιεχομένου έχει τροποποιηθεί επιτυχώς',
  'Le gap sémantique de ce mot clé n’a pas encore été validé, veuillez contacter le support Typix : support-typix@pixalione.com':
    'Το σέμαντικ κενό αυτής της λέξης-κλειδί δεν έχει ακόμη επικυρωθεί, παρακαλούμε επικοινωνήστε με την υποστήριξη Typix: support-typix@pixalione.com',
  'Ce mot clé n’existe pas sur MONETORING, veuillez vérifier avec le support Typix : support-typix@pixalione.com':
    'Αυτή η λέξη-κλειδί δεν υπάρχει στο MONETORING, παρακαλούμε ελέγξτε με την υποστήριξη Typix: support-typix@pixalione.com',
  'Récupérer le Gap': 'Ανάκτηση του κενού',
  'Traduction premium': 'Premium μετάφραση',
  'Rédaction et/ou Traduction premium': 'Premium συγγραφή και/ή μετάφραση',
  'Rédaction et/ou Traduction avec révision':
    'Συγγραφή και/ή μετάφραση με αναθεώρηση',
  'Rédaction et/ou Traduction intelligente': 'Έξυπνη συγγραφή και/ή μετάφραση',
  'Traduction avec révision': 'Μετάφραση με αναθεώρηση',
  'Traduction intelligente': 'Έξυπνη μετάφραση',
};
