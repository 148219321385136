import { useCallback, useEffect, useState } from 'react';

import axiosPrivate from 'config/axiosPrivate';

import { useDispatch, useSelector } from 'react-redux';

import {
  adminOrderDisplaySelector,
  updateOrderDetails,
  updateRemainingTextAmount,
} from 'store/Admin/OrderDisplay';
import { updateOrder } from 'store/Admin/OrderList';

import { CustomAutocomplete } from 'components/shared/DropDown/CustomAutocomplete';
import { CustomChipsAutocomplete } from 'components/shared/DropDown/CustomChipsAutocomplete';
import { Loader } from 'components/shared/Loader';
import { UpDownInputArrows } from 'components/shared/UpDownInputArrows';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { ORDERSTATUS, ORDER_CONTENT_TYPE } from 'utils/Constants';

import { Table, TableBody } from '@mui/material';
import ErrorComponent from 'components/shared/ErrorComponent';
import { disabledInput } from 'components/shared/disabledClasses';
import axiosEvaluation from 'config/axiosEvaluation';
import { useTranslation } from 'react-i18next';
import Evaluation from './Evaluation';
import { ChevronDownIcon, ChevronUpIcon } from 'icons/ChevronIcon';
import { EDITORS_LIST } from 'utils/Constants';

const API = {
  getAllThematics: '/thematic/all',
  getAllLanguages: '/language/all',
  updateOrder: '/commands/',
  getRatingsForCommand: '/admin/evaluations/commandEvaluation',
};

const editorsList = EDITORS_LIST;

const contentTypeList = [
  { name: 'Fiche produit courte', type: 'PRODUCT' },
  { name: 'Fiche produit longue', type: 'LONG_PRODUCT' },
  { name: 'GMB', type: 'GMB' },
  { name: 'STORE_LOCATOR', type: 'STORE_LOCATOR' },
  { name: 'Netlinking', type: 'NETLINKING' },
  { name: 'Autre', type: 'OTHER' },
  { name: 'Category', type: 'LONG_CATEGORY' },
  { name: 'Enrechissement contenu', type: 'CONTENT_IMPROVEMENT' },
  { name: 'Traduction', type: 'TRANSLATION' },
];

export default function OrderDetails(props) {
  let { order, status, affectedTextAmount, isLoading, isError } = useSelector(
    adminOrderDisplaySelector
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [writersRating, setWritersRating] = useState(null);
  const [availableLanguagesList, setAvailableLanguagesList] = useState([]);
  const [availableThematicsList, setAvailableThematicsList] = useState([]);
  const [ratingStatus, setRatingStatus] = useState('idle');

  const [title, setTitle] = useState('');
  const [consultant, setConsultant] = useState(null);
  const [clientsList, setClientsList] = useState([]);
  const [writers, setWriters] = useState([]);

  const [textAmount, setTextAmount] = useState(0);
  const [minWords, setMinWords] = useState(0);
  const [maxWords, setMaxWords] = useState(0);
  const [deadline, setDeadline] = useState(null);
  const [price, setPrice] = useState(0);
  const [orderLanguage, setOrderLanguage] = useState(null);
  const [orderThematic, setOrderThematic] = useState([]);
  const [editor, setEditor] = useState('');
  const [entitled, setEntitled] = useState('');
  const [siloName, setSiloName] = useState('');
  const [disabledForm, setDisabledForm] = useState(true);
  const [orderType, setOrderType] = useState('');

  const [detailedBrief, setDetailedBrief] = useState('');
  const [semanticGap, setSemanticGape] = useState('');
  const [clientDocumentation, setClientDocumentation] = useState('');
  const [contentType, setContentType] = useState('');

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const getRatingsForCommand = useCallback(async () => {
    setRatingStatus('loading');
    try {
      const response = await axiosEvaluation.get(
        `/admin/evaluations/commandEvaluation?command=${order.id}`
      );
      setWritersRating(response.data.evaluationWriterCommandList);
      setRatingStatus('succeeded');
    } catch (error) {}
  }, [order.id]);

  useEffect(() => {
    axiosPrivate
      .get(API.getAllLanguages)
      .then((res) => setAvailableLanguagesList(res.data));
    axiosPrivate
      .get(API.getAllThematics)
      .then((res) => setAvailableThematicsList(res.data));
  }, []);
  useEffect(() => {
    getRatingsForCommand();
  }, [getRatingsForCommand]);

  useEffect(() => {
    if (!isLoading && !isError) {
      setConsultant(order.consultant);
      setClientsList(order.clients);
      setWriters(order.writers);
      setEditor(order.editorialContact);
      setEntitled(order.titled);
      setSiloName(order.nomSilo);
      let index = order.title.indexOf('-', order.title.indexOf('-') + 1);
      setTitle(order.title.slice(0, index + 2));
      setTextAmount(order.textAmount);
      setDetailedBrief(order.detailedBrief);
      setClientDocumentation(order.clientDocumentation);
      setSemanticGape(order.semanticGap);
      setContentType(order.contentType);
      setMinWords(order.minWords);
      setMaxWords(order.maxWords);
      setDeadline(order.deadline ? order.deadline.slice(0, 10) : '');
      setPrice(order.price);
      setOrderLanguage(order.language);
      setOrderThematic(order.thematics);
      setOrderType(order.type);
    }
  }, [order, isLoading, isError]);

  const checkValidForm = useCallback(() => {
    if (
      !orderLanguage ||
      !semanticGap ||
      !textAmount ||
      orderThematic.length === 0 ||
      !entitled
    ) {
      setDisabledForm(true);
      return;
    }
    setDisabledForm(false);
  }, [entitled, orderLanguage, orderThematic, semanticGap, textAmount]);

  useEffect(() => {
    checkValidForm();
  }, [
    detailedBrief,
    orderLanguage,
    semanticGap,
    textAmount,
    orderThematic,
    entitled,
    checkValidForm,
  ]);

  const callbackFromMultiSelect = (newList) => {
    setOrderThematic(newList);
  };

  const handleUpdate = () => {
    if (textAmount < affectedTextAmount) {
      setErrorSuccessMsg(
        t(
          "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande"
        )
      );
      setIsSuccess(false);
      setErrorSuccess(true);
      return;
    }
    let body = {
      textAmount,
      language: orderLanguage,
      detailedBrief,
      clientDocumentation,
      semanticGap,
      thematics: orderThematic,
      titled: entitled,
      editorialContact: editor,
      contentType,
      nomSilo: siloName,
    };

    axiosPrivate
      .put(API.updateOrder + order.id, body)
      .then((res) => {
        dispatch(updateOrderDetails(res.data));

        dispatch(updateRemainingTextAmount(body.textAmount));

        dispatch(
          updateOrder({
            orderId: order.id,
            status,
            textAmount: res.data.textAmount,
            title: res.data.title,
          })
        );

        setErrorSuccessMsg(t('La commande a été mis à jour avec succès !'));
        setIsSuccess(true);
        setErrorSuccess(true);
      })
      .catch((e) => {
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(t('Le titre de la commande existe déjà'));
            break;
          case 404:
            setErrorSuccessMsg(t("Cette commande n'existe pas"));
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setIsSuccess(false);
        setErrorSuccess(true);
      });
  };

  if (isLoading) return <Loader />;

  if (isError) return <ErrorComponent />;

  return (
    <div>
      <div className="box-tabs-content">
        <div className="box-wrapper">
          <div className="box-wrap">
            <div className="row row-mb">
              <div className="col-12 col-md-12">
                <div className="label-title">{t('Titre de la commande')}</div>
                <div
                  className="form-control form-ct h-75"
                  style={disabledInput}
                >
                  {title} &nbsp;
                  {order.nomSilo && (
                    <>
                      <input
                        type="text"
                        value={siloName}
                        style={{
                          paddingLeft: '.5em',
                          paddingRight: '.5em',
                          width: siloName?.length + 3 + 'ch',
                        }}
                        className="white-input"
                        onChange={(e) => setSiloName(e.target.value)}
                      />{' '}
                      - &nbsp;
                    </>
                  )}
                  <input
                    type="text"
                    value={entitled}
                    style={{
                      paddingLeft: '.5em',
                      paddingRight: '.5em',
                      width: entitled?.length + 3 + 'ch',
                    }}
                    className="white-input"
                    onChange={(e) => setEntitled(e.target.value)}
                  />
                  &nbsp; - {textAmount}
                </div>
              </div>
            </div>
            <div className="row row-mb">
              <div className="col-12 col-md-3">
                <div className="label-title">{t('Consultant')}</div>
                <input
                  style={disabledInput}
                  type="text"
                  disabled
                  value={consultant ? consultant?.name : ''}
                  className="form-control form-ct"
                />
              </div>
              <div className="col-12 col-md-5">
                <div className="label-title">{t('Mail clients')}</div>
                <div className="dropdown" style={{ zIndex: 999 }}>
                  <button
                    style={{ ...disabledInput, textTransform: 'none' }}
                    className="btn dropdown-toggle dropdown-custom w-100"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {clientsList.length > 0 ? clientsList[0].mail : ''}
                    <span style={disabledInput}>
                      <ChevronDownIcon />
                    </span>
                  </button>
                  {clientsList.length > 0 && (
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {clientsList.map((client) => (
                        <li key={client.id}>
                          <button className="dropdown-item">
                            {' '}
                            {client.mail}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="label-title">{t('Url du site')}</div>
                <input
                  style={disabledInput}
                  type="text"
                  disabled
                  value={order.website.urlSite}
                  className="form-control form-ct"
                />
              </div>
            </div>
            <div className="row row-mb">
              <div className=" col-12 col-md-6">
                <div className="label-title">{t('Mail rédacteurs')}</div>
                {writers.length === 0 ? (
                  <button
                    className="btn dropdown-toggle dropdown-custom w-100"
                    style={{ ...disabledInput, textTransform: 'none' }}
                  >
                    {' '}
                    Pas de Rédacteurs{' '}
                  </button>
                ) : (
                  <div className="dropdown" style={{ zIndex: 998 }}>
                    <button
                      style={{ ...disabledInput, textTransform: 'none' }}
                      className="btn dropdown-toggle dropdown-custom w-100"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {writers[0].mail}
                      <span style={disabledInput}>
                        <ChevronDownIcon />
                      </span>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {writers.map((writer) => (
                        <li key={writer.id}>
                          <button className="dropdown-item">
                            {writer.mail}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="col-12 col-md-6">
                {editor != null ? (
                  <div>
                    <div className="label-title">{t('Chef edito')}</div>
                    <div>
                      <CustomAutocomplete
                        disabled={editorsList.length <= 0}
                        list={editorsList}
                        value={editor}
                        setValue={(option) => setEditor(option)}
                        groupBy={(option) => false}
                        getOptionSelected={(option) => option === editor}
                        getOptionDisabled={(option) => false}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    {status !== ORDERSTATUS.NONATTRIBUTED ? (
                      <div className="col-12 col-md-6">
                        <div className="label-title">
                          {t('Nombre de mots par texte')}
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="number-wrapper input-group input-gr number-group">
                              <input
                                value={minWords || ''}
                                style={disabledInput}
                                disabled
                                type="number"
                                className="form-control form-ct"
                              />
                              <UpDownInputArrows disabled={true} />
                              <span
                                style={disabledInput}
                                className="input-group-text"
                              >
                                {t('Min.')}
                              </span>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="number-wrapper input-group input-gr number-group">
                              <input
                                style={disabledInput}
                                type="number"
                                disabled
                                value={maxWords || ''}
                                className="form-control form-ct"
                              />
                              <UpDownInputArrows disabled={true} />
                              <span
                                style={disabledInput}
                                className="input-group-text"
                              >
                                {t('Max.')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            {editor != null ? (
              <div className="row row-mb">
                {contentType && (
                  <div className="col-12 col-md-6">
                    <div className="label-title">{t('Type de contenu')}</div>
                    <div>
                      {orderType !== 'PREMIUM' ? (
                        <CustomAutocomplete
                          disabled={contentTypeList.length <= 0}
                          list={contentTypeList}
                          value={contentTypeList.find(
                            (option) => option.type === contentType
                          )}
                          setValue={(option) =>
                            setContentType(option ? option.type : '')
                          }
                          groupBy={(option) => false}
                          getOptionSelected={(option, value) =>
                            option.type === value
                          }
                          getOptionDisabled={(option) => false}
                          getOptionLabel={(option) => option.name}
                        />
                      ) : (
                        <input
                          style={disabledInput}
                          type="text"
                          disabled
                          value={t(
                            contentTypeList.find(
                              (item) => item.type === contentType
                            )?.name || ''
                          )}
                          className="form-control form-ct"
                        />
                      )}
                    </div>
                  </div>
                )}

                {status !== ORDERSTATUS.NONATTRIBUTED ? (
                  <div className="col-12 col-md-6">
                    <div className="label-title">
                      {t('Nombre de mots par texte')}
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="number-wrapper input-group input-gr number-group">
                          <input
                            value={minWords || ''}
                            style={disabledInput}
                            disabled
                            type="number"
                            className="form-control form-ct"
                          />
                          <UpDownInputArrows disabled={true} />
                          <span
                            style={disabledInput}
                            className="input-group-text"
                          >
                            {t('Min.')}
                          </span>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="number-wrapper input-group input-gr number-group">
                          <input
                            style={disabledInput}
                            type="number"
                            disabled
                            value={maxWords || ''}
                            className="form-control form-ct"
                          />
                          <UpDownInputArrows disabled={true} />
                          <span
                            style={disabledInput}
                            className="input-group-text"
                          >
                            {t('Max.')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="row row-mb">
              <div
                className={` col-12 ${
                  status !== ORDERSTATUS.NONATTRIBUTED ? 'col-md-3' : 'col-md-6'
                }`}
              >
                <div className="label-title">{t('Langue')}</div>
                <CustomAutocomplete
                  disabled={!availableLanguagesList}
                  list={availableLanguagesList}
                  value={orderLanguage}
                  setValue={(option) => setOrderLanguage(option)}
                  groupBy={(option) => false}
                  getOptionSelected={(option) =>
                    option.language === orderLanguage.language
                  }
                  getOptionDisabled={(option) => false}
                  getOptionLabel={(option) => t(option.language)}
                />
              </div>
              <div
                className={` col-12 ${
                  status !== ORDERSTATUS.NONATTRIBUTED ? 'col-md-3' : 'col-md-6'
                }`}
              >
                <div className="label-title">{t('Nombre de textes')}</div>
                <div className="number-wrapper">
                  <input
                    type="number"
                    min="0"
                    className="form-control form-ct"
                    value={textAmount}
                    onChange={(e) => setTextAmount(e.target.value)}
                  />
                  <UpDownInputArrows disabled={false} />
                </div>
              </div>
              {status !== ORDERSTATUS.NONATTRIBUTED ? (
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Deadline')}</div>
                  <div className="wrapper-date">
                    <input
                      type="text"
                      className="form-control form-ct"
                      disabled
                      style={disabledInput}
                      value={deadline ? deadline : ''}
                    />
                    <div id="date-button" className="box-icon-calendar">
                      <img
                        src="/Images/icon-calendar-no-background.png"
                        alt="icon-calendar"
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {status !== ORDERSTATUS.NONATTRIBUTED ? (
                <div className="col-12 col-md-3">
                  <div className="label-title">{t('Tarif au mot')}</div>
                  <div className="number-wrapper input-group input-grl">
                    <span style={disabledInput} className="input-group-text">
                      €
                    </span>
                    <input
                      type="number"
                      className="form-control form-ct"
                      disabled
                      style={disabledInput}
                      value={price}
                    />
                    <UpDownInputArrows disabled={true} />
                  </div>
                </div>
              ) : null}
            </div>

            <div className="row row-mb">
              <div className="col-12 col-md-4">
                <div className="label-title">{t('Brief détaillé')}</div>
                <div>
                  <input
                    type="text"
                    value={detailedBrief}
                    onChange={(e) => setDetailedBrief(e.target.value)}
                    className="form-control form-control-valid form-ct"
                    placeholder="Lien drive..."
                    style={{ padding: '0px 8px' }}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div
                  className={!semanticGap ? 'label-title-error' : 'label-title'}
                >
                  {t('Gap sémantique')}*
                </div>
                <div>
                  <input
                    type="text"
                    value={semanticGap}
                    onChange={(e) => setSemanticGape(e.target.value)}
                    className={
                      !semanticGap
                        ? 'form-control form-control-error form-ct'
                        : 'form-control form-control-valid form-ct'
                    }
                    placeholder="Lien drive..."
                    style={{ padding: '0px 8px' }}
                  />
                </div>
                {!semanticGap ? (
                  <p className="error-note">{t('Ce champs est obligatoire')}</p>
                ) : null}
              </div>
              <div className="col-12 col-md-4">
                <div className="label-title">{t('Documentation')}</div>
                <div>
                  <input
                    type="text"
                    value={clientDocumentation}
                    onChange={(e) => setClientDocumentation(e.target.value)}
                    className="form-control form-control-valid form-ct"
                    placeholder="Lien drive"
                    style={{ padding: '0px 8px' }}
                  />
                </div>
              </div>
            </div>
            <div className="row row-mb">
              <div className="col-12 col-md-12">
                <div className="label-title">
                  {t('Thématiques d’expertise')}
                </div>
                <CustomChipsAutocomplete
                  callbackFromMultiSelect={callbackFromMultiSelect}
                  value={orderThematic}
                  disabled={!availableThematicsList}
                  list={availableThematicsList}
                  setValue={(option) => setOrderThematic(option)}
                  getOptionLabel={(option) => t(option.thematic) || ''}
                />
              </div>
            </div>
            {ratingStatus === 'succeeded' && (
              <div>
                <div className="label-title mb-2">
                  {writersRating?.length > 1
                    ? t('Notes rédacteurs')
                    : writersRating?.length > 0
                    ? t('Notes rédacteur')
                    : ''}
                </div>
                <Table
                  style={{
                    borderCollapse: 'collapse',

                    background: 'transparent',
                  }}
                >
                  <TableBody
                    sx={{
                      '& .MuiTableRow-root:not(:last-child)': {
                        borderBottom: 'none',
                      },
                    }}
                  >
                    {writersRating.map((writersRating, index) => (
                      <Evaluation
                        key={index}
                        writersRating={writersRating}
                        index={index}
                        orderID={order.id}
                        t={t}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}

            {status === ORDERSTATUS.VALID && ratingStatus === 'loading' && (
              <Loader />
            )}
            <div className="box-rectangle" />
          </div>
        </div>
        <div className="box-button-bt">
          <div>
            {disabledForm ? (
              <button className="bt-submit-disabled-y">
                {' '}
                {t('Sauvegarder')}
              </button>
            ) : (
              <div onClick={handleUpdate} className="bt-submit-y">
                {t('Sauvegarder')}
              </div>
            )}
          </div>
        </div>
      </div>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
}
